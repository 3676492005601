
    import {computed, defineComponent, reactive} from 'vue';

    export default defineComponent({
        name: "Alert",
        props: {
            title: {
                type: String,
                default: '',
                required: true,
            },
            message: {
                type: String,
                default: '',
            },
            type: {
                type: String,
                default: 'info',
                required: true,
                validator: (value: string) => ['info', 'success', 'warning', 'error'].indexOf(value) !== -1
            },
          rounded: {
              type: Boolean,
              default: true
          }
        },
        setup(props, context) {
            let icon = 'fas fa-info-circle';
            const colors = reactive({
                background: 'bg-red-50',
                icon: 'text-red-400',
                text: 'text-red-800',
                description: 'text-red-700',
            })
            switch(props.type) {
                case 'error' :
                    icon = 'fas fa-exclamation-circle';
                    break;
              case 'info' :
                icon = 'fas fa-info-circle';
                colors.background = 'bg-blue-50';
                colors.icon = 'text-blue-400';
                colors.text = 'text-blue-800';
                colors.description = 'text-blue-700';
                break;
              case 'warning' :
                icon = 'fas fa-exclamation-triangle';
                colors.background = 'bg-yellow-50';
                colors.icon = 'text-yellow-400';
                colors.text = 'text-yellow-800';
                colors.description = 'text-yellow-700';
                break;
                case 'success' :
                    icon = 'fas fa-check-circle';
                    colors.background = 'bg-green-50';
                    colors.icon = 'text-green-400';
                    colors.text = 'text-green-800';
                    colors.description = 'text-green-700';
                    break;
            }
            return {
                icon,
                colors,
            }
        }
    })
