
    import {Form, FormGrid} from '@/types/form';
    import {AvatarField, BaseField, Field, FieldType, MultipleOptionField, StarsField, TagField, ListField} from "@/types/field";
    import {computed, defineComponent, nextTick, PropType, reactive, ref, watch} from 'vue';
    import {BaseValidationRule, FormValidation, MaxLengthValidationRule} from "@/types/validation";
    import {LogicMatch, LogicMethod, LogicRuleOperator} from "@/types/logic";
    import {useTranslation} from "@/plugins/i18n";
    import useUid from "@/components/useUid";
    import {Option} from "@/types/option";

    export default defineComponent({
        name: "DataForm",
        props: {
            form: {
                type: Object as PropType<Form>,
                required: true,
            },
        },
        emits: ['enter'],
        setup(props, {attrs, emit}) {

            //  Translation plugin
            const i18n = useTranslation();

            //  Form object
            const form = ref<Form>(props.form).value;

            //  Form values object
            const values: { [key: string]: any } = (attrs.modelValue as object);

            //  Check if form has run validation
            let executedValidation = false;

            //  List with invalid fields
            let invalidFields: { [key: string]: any } = reactive({});

            //  Execute validation for given rules
            const validate = (field: BaseField, value: any): Promise<any> => {
                return new Promise((resolve, reject) => {
                    //  Execute the validate method for each validation rule
                    const rules: any[] = [];
                    if (typeof field.rules != 'undefined') {
                        field.rules.forEach(rule => {
                            rules.push(rule.validate(field, value));
                        });
                    }
                    Promise.all(rules).then(() => resolve()).catch(errors => reject(errors));
                });
            }

            //  If values has changes
            watch(() => values, (values) => {
                //  Next tick because of field logic that can change the DOM elements
                nextTick(() => {
                    //  Find select field with an invalid value
                    form.fields.forEach(field => {
                        //  Filter only select fields
                        if (field.type === FieldType.Select && field.options.map(option => option.value).indexOf(values[field.model]) === -1 && field.visible && field.options.length > 0) {
                            values[field.model] = field.options[0].value;
                        }
                    })
                })
                //  Check if form should be validated
                if (form.submitted || form.validate === FormValidation.Immediately) {
                    form.onSubmitted().then(() => {
                    }).catch(() => {
                    });
                }
            }, {immediate: true, deep: true});

            //  Remove field from validation if field is not visible anymore
            const removeFieldForValidation = (field: BaseField) => {
                //  Check if field is present in invalid fields collection
                if (Object.keys(invalidFields).indexOf(field.model) > -1 && form.fields.filter(f => f.model === field.model && f.visible).length === 0) {
                    delete invalidFields[field.model];
                }
            }

            //  Filter all fields based on logic conditions
            const fields = computed(() => form.fields.filter(field => {

                //  If field has no logic conditions show the field
                field.visible = true;
                if (!field.logic) return true;

                //  Count all matching rules
                let matches = 0;
                field.logic.rules.forEach(rule => {
                    if ((values[rule.model] === rule.value && rule.operator === LogicRuleOperator.Is) || (values[rule.model] !== rule.value && rule.operator === LogicRuleOperator.IsNot)) {
                        matches++;
                    }
                });

                //  Show field if field should be hidden when has matches
                if (matches === 0 && field.logic.operator === LogicMethod.Hide) return true;

                //  Check if field should be visible
                if ((matches === field.logic.rules.length && field.logic.match === LogicMatch.All) || (matches > 0 && field.logic.match === LogicMatch.Any)) {
                    if (field.logic.operator === LogicMethod.Show) {
                        return true;
                    }
                }

                //  Hide field
                removeFieldForValidation(field as BaseField);
                field.visible = false;
                return false;
            }));

            //  Get field caption text
            const getCaption = (field: BaseField) => {
                return field.translate ? i18n.translate(field.caption) : field.caption;
            }

            //  Get field placeholder text
            const getPlaceholder = (field: BaseField) => {
                return field.placeholder ? field.translate ? i18n.translate(field.placeholder) : field.placeholder : '';
            }

            // get class
            const getClass = (width: number): string => {
                return ['sm:col-span-1', 'sm:col-span-2', 'sm:col-span-3', 'sm:col-span-4', 'sm:col-span-5', 'sm:col-span-6', 'sm:col-span-7', 'sm:col-span-8', 'sm:col-span-9', 'sm:col-span-10', 'sm:col-span-11', 'sm:col-span-12'][width - 1]
            }

            //  Get component uid
            const uid = useUid().uid;

            //  Validate a field
            const onValidate = (field: BaseField, value: any): Promise<any> => {
                return new Promise((resolve, reject) => {
                    if (!field.visible) {
                        delete invalidFields[field.model];
                        resolve();
                    } else {
                        validate(field, value).then(() => {
                            delete invalidFields[field.model];
                            resolve();
                        }).catch(error => {
                            invalidFields[field.model] = error;
                            reject();
                        });
                    }
                });
            }

            //  Field value change event
            const onChange = (field: BaseField, event: any) => {
                event.preventDefault();
                if (form.submitted || form.validate === FormValidation.Immediately) {
                    onValidate(field, event.target.value).then(() => {
                    }).catch(() => {
                    });
                }
            }

            //  Get validation error
            const getError = (field: BaseField): string | null => {
                if (!invalidFields[field.model]) return null;
                return i18n.translate(invalidFields[field.model].message, invalidFields[field.model].args);
            }

            //  When form is submitted
            form.onSubmitted = (): Promise<any> => {
                form.submitted = true;
                return new Promise((resolve, reject) => {
                    const validations: Promise<any>[] = [];
                    form.fields.forEach(field => {
                        if (field.visible) {
                            validations.push(onValidate(field as BaseField, values[field.model]));
                        }
                    })
                    Promise.all(validations).then(() => resolve()).catch(() => reject());
                })
            }

            const clickSelectButton = (field: MultipleOptionField, option: Option, event: any) => {
                event.target.blur();
                if (field.multiple) {
                    const index = values[field.model].indexOf(option.value);
                    if (index === -1) {
                        values[field.model].push(option.value);
                    } else {
                        values[field.model].splice(index, 1)
                    }
                } else {
                    values[field.model] = option.value;
                }
                if (form.submitted || form.validate === FormValidation.Immediately) {
                    onValidate(field, values[field.model]).then(() => {
                    }).catch(() => {
                    });
                }
            }

            const onEnter = () => emit('enter')

            const avatarSelected = (field: AvatarField, event: any) => {
                const file = event.target.files.length > 0 ? event.target.files[0] : null;
                field.callback(file, field);
            }

            const getAvatarImage = (field: AvatarField): string => {
                if (values[field.model] === '' || field.uploading) return '';
                return `background-image: url(${values[field.model]});`;
            }

            const calculateStar = (star: number, half: boolean, event: any): null | number => {
                let stars = half ? star * 2 : star;
                if ((event.target.offsetWidth / 2) > event.clientX - event.target.getBoundingClientRect().left && half) {
                    stars -= 1;
                }
                return stars;
            }

            const hoverStar = (star: number, field: StarsField, event: any) => {
                field.hover = calculateStar(star, field.half, event);
            }

            const setStar = (star: number, field: StarsField, event: any) => {
                values[field.model] = calculateStar(star, field.half, event);
            }

            const getColumns = (columns: number): string => {
                const list = [
                    'lg:grid-cols-1',
                    'lg:grid-cols-2',
                    'lg:grid-cols-3',
                    'lg:grid-cols-4',
                    'lg:grid-cols-5',
                    'lg:grid-cols-6',
                    'lg:grid-cols-7',
                    'lg:grid-cols-8',
                    'lg:grid-cols-9',
                    'lg:grid-cols-10',
                ];
                return list[columns - 1] ?? 'lg:list-grid-cols-1';
            }

            const getRows = (row: number): string => {
                const list = [
                    'lg:grid-rows-1',
                    'lg:grid-rows-2',
                    'lg:grid-rows-3',
                    'lg:grid-rows-4',
                    'lg:grid-rows-5',
                    'lg:grid-rows-6',
                    'lg:grid-rows-7',
                    'lg:grid-rows-8',
                    'lg:grid-rows-9',
                    'lg:grid-rows-10',
                    'lg:grid-rows-11',
                    'lg:grid-rows-12',
                    'lg:grid-rows-13',
                    'lg:grid-rows-14',
                    'lg:grid-rows-15',
                    'lg:grid-rows-16',
                    'lg:grid-rows-17',
                    'lg:grid-rows-18',
                    'lg:grid-rows-19',
                    'lg:grid-rows-20',
                    'lg:grid-rows-21',
                    'lg:grid-rows-22',
                    'lg:grid-rows-23',
                    'lg:grid-rows-24',
                    'lg:grid-rows-25',
                    'lg:grid-rows-26',
                    'lg:grid-rows-27',
                    'lg:grid-rows-28',
                    'lg:grid-rows-29',
                    'lg:grid-rows-30',
                ];
                return list[row - 1] ?? 'lg:grid-rows-1';
            }

            const tags: { [key: string]: any } = reactive({});
            const newTags: { [key: string]: any } = reactive({});
            const tagRefs = ref({});

            watch(() => newTags, (tags) => {
                form.fields.forEach((field: Field): void => {
                    if (field.type === FieldType.Tag) {
                        if (typeof newTags[field.model] === 'undefined') {
                            newTags[field.model] = (field as TagField).createPlaceholder;
                        }
                    }
                });
            }, {immediate: true, deep: true});

            const insertTag = (field: TagField, event: any): void => {
                const hashtags = (tags[field.model] ?? '').split(' ').map((item: string) => item.trim().toLowerCase()).filter((item: string) => item !== '');
                hashtags.forEach((tag: string) => {
                    if (values[field.model].indexOf(tag) === -1) {
                        values[field.model].push(tag);
                    }
                });
                tags[field.model] = '';
                onChangedTags(field);
            }

            const removeLastTag = (field: TagField, event: any): void => {
                if (tags[field.model] === '' && values[field.model].length > 0) {
                    tags[field.model] = values[field.model][values[field.model].length - 1];
                    values[field.model].splice(values[field.model].length - 1, 1);
                }
                onChangedTags(field);
            }

            const onEditTag = (field: TagField, tagIndex: number, event: any) => {
                const tag = event.target.innerText;
                values[field.model][tagIndex] = tag;
            }

            const onEditNewTag = (field: TagField, event: any) => {
                const tag = event.target.innerText;
                newTags[field.model] = tag;
            }

            const onFocusNewTag = (field: TagField) => {
                if (newTags[field.model] === field.createPlaceholder || typeof newTags[field.model] === 'undefined') {
                    newTags[field.model] = '';
                }
            }

            const addNewTag = (field: TagField) => {
                const tag = typeof newTags[field.model] !== 'undefined' ? newTags[field.model] : '';
                if (tag !== '' && tag !== field.createPlaceholder) {
                    values[field.model].push(tag);
                    newTags[field.model] = field.createPlaceholder;
                }
                newTags[field.model] = field.createPlaceholder;
                onChangedTags(field);
            }

            const onTabKeyDown = (field: TagField, event: any): void => {
                const tag = typeof newTags[field.model] !== 'undefined' ? newTags[field.model] : '';
                if ((event.keyCode === 9 || event.keyCode === 13) && tag !== '' && tag !== field.createPlaceholder) {
                    addNewTag(field);
                    newTags[field.model] = '';
                    event.preventDefault();
                }
            }

            const onChangedTags = (field: TagField) => {
                field.onChangedCallback(values[field.model]);
            }

            const canAddNewItem = (field: ListField) : boolean => {
                let canAddNewItem = true;
                field.rules.forEach((rule: BaseValidationRule) => {
                    if(rule.constructor.name === MaxLengthValidationRule.name) {
                        canAddNewItem = (rule as MaxLengthValidationRule).length > values[field.model].length;
                    }
                });
                return canAddNewItem;
            }

            return {
                values,
                fields,
                uid,
                FieldType,
                ListField,
                FormGrid,
                data: values,
                getCaption,
                getPlaceholder,
                onChange,
                invalidFields,
                getError,
                clickSelectButton,
                onEnter,
                avatarSelected,
                getAvatarImage,
                hoverStar,
                setStar,
                getClass,
                getColumns,
                getRows,
                tags,
                newTags,
                insertTag,
                removeLastTag,
                onEditTag,
                onEditNewTag,
                onFocusNewTag,
                addNewTag,
                onChangedTags,
                tagRefs,
                onTabKeyDown,
                canAddNewItem,
            }

        }
    })
