export interface Step {
    uid: string,
    icon: string,
    name: string,
    description: string,
    previous: string,
    next: string,
    callback: Function,
    visible: boolean,
    saving: string,
    load: Function,
}

export class BaseStep implements Step {
    uid: string = ''
    icon: string = ''
    name: string = ''
    description: string = ''
    previous: string = ''
    next: string = ''
    callback: Function = (): Promise<any> => Promise.resolve();
    visible: boolean = true
    saving: string = ''
    load: Function = () => {
    }

    constructor(uid: string, name: string, description: string, icon: string, previous: string, next: string, callback: Function, saving: string, load: Function) {
        this.uid = uid;
        this.icon = icon;
        this.name = name;
        this.description = description;
        this.previous = previous;
        this.next = next;
        this.callback = callback;
        this.visible = true;
        this.saving = saving;
        this.load = load;
    }
}

export interface Steps {
    steps: Step[]
    loading: boolean
    current: number
}

export class BaseSteps implements Steps {
    steps: Step[] = [];
    loading: boolean = false;
    current: number = 0;

    constructor() {
        this.steps = [];
        this.current = 0;
    }

    public validateStep(validate: Promise<any>, step: number = 0): Promise<any> {
        return validate.then(() => Promise.resolve(3)).catch(() => Promise.reject(step));
    }

    //  Reject step that failed
    // @ts-ignore
    public validateSteps(steps: Function<Promise>[], step: number = 0): Promise<any> {
        const method: Function = steps[step];
        method(step).then(() => { // 100 ms
            if (steps.length - 1 >= step) {
                return this.validateSteps(steps, step + 1);
            } else {
                return Promise.resolve(1);
            }
        }).catch(() => {
            return Promise.reject(1);
        });
    }

    public processPromises(array: Array<any>, fn: Function)
    {
        return array.reduce((promise, item) => {
            return promise.then(() => {
                return fn(item);
            })
        }, Promise.resolve());
    }

    // @ts-ignore
    public validate(goto: number): Promise<any> {
        if (goto === 0) {
            this.current = 0;
            this.changedCurrent();
            this.loading = false;
            return Promise.resolve();
        }
        const validate: Promise<any>[] = [];

        //  Validate all previous steps
        this.steps.filter(step => step.visible).forEach((step, index) => {
            if (index < goto && index >= this.current) {
                validate.push(step.callback());
            }
        });

        console.log('Fix promises', validate);

        Promise.allSettled(validate)
            .then((results) => {
                const rejected: Array<number> = [];
                results.forEach((result: { status: string }, index: number) => {
                    if (result.status === 'rejected' && rejected.length === 0) {
                        rejected[0] = index;
                    }
                });
                if (rejected.length > 0) {
                    this.current += rejected[0];
                } else {
                    this.current = goto === this.steps.length ? this.steps.length - 1 : goto;
                }
                this.loading = false;
                this.changedCurrent();
                return Promise.resolve();
            });
    }

    public changedCurrent() {
        setTimeout(() => {
            const step = this.steps.filter(step => step.visible)[this.current];
            if (step) {
                step.load();
            }
        }, 100);
    }

    public next(): Promise<any> {
        if (this.current >= this.steps.filter(step => step.visible).length || this.loading) {
            return Promise.resolve();
        }
        this.loading = true;
        return this.validate(this.current + 1);
    }

    public previous(): Promise<any> {
        if (this.current <= 0 || this.loading) {
            return Promise.resolve();
        }
        this.loading = true;
        return this.validate(this.current - 1);
    }

    public set(step: string): Promise<any> {
        this.loading = true;
        return this.validate(this.steps.filter(step => step.visible).map(step => step.uid).indexOf(step));
    }
}