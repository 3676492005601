import {inject} from 'vue';

import {ConfirmModal, confirmStore, ConfirmType} from '@/store/confirm';

export class Confirm {

    constructor(options: any) {
    }

    info(title: string, message: string, submit?: string, cancel?: string): Promise<any> {
        return this.show(ConfirmType.Info, title, message, submit, cancel);
    }

    warning(title: string, message: string, submit?: string, cancel?: string): Promise<any> {
        return this.show(ConfirmType.Warning, title, message, submit, cancel);
    }

    delete(title: string, message: string, submit?: string, cancel?: string): Promise<any> {
        return this.show(ConfirmType.Error, title, message, submit || 'confirm.delete', cancel);
    }

    show(type: ConfirmType, title: string, message: string, submit?: string, cancel?: string): Promise<any> {
        return new Promise((resolve, reject) => {
            const modal = new ConfirmModal();
            modal.type = type;
            modal.title = title;
            modal.message = message;
            modal.cancel = cancel || 'confirm.cancel';
            modal.submit = submit || 'confirm.close';
            modal.confirmed = () => {
                resolve();
            }
            modal.rejected = () => {
                reject();
            }
            confirmStore.show(modal);
        })
    }

}

export const ConfirmSymbol = Symbol();

export function useConfirm() {
    const confirm = inject(ConfirmSymbol);
    if (!confirm) throw new Error('Plugin not injected');
    return new Confirm({});
}

export default {
    install: (app: any, options: any) => {
        if (!options) options = {};
        const Plugin = new Confirm(options);
        app.config.globalProperties.$confirm = Plugin;
        app.provide(ConfirmSymbol, Plugin)
    }
}