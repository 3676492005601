<template>
    <div class="min-h-screen bg-white flex flex-row-reverse">
        <div class="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24 min-w-1/2 w-2/6">
            <div class="mx-auto w-full max-w-sm text-left pb-5 sm:pb-10">
                <img class="h-20" src="../assets/logos/brand-ambassador-dark.svg">
            </div>
            <router-view></router-view>
        </div>
        <div class="hidden lg:block relative w-0 flex-1">
            <img class="absolute inset-0 h-full w-full object-cover"
                 src="https://images.unsplash.com/photo-1505904267569-f02eaeb45a4c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1908&q=80"
                 alt="">
        </div>
    </div>
</template>

<script lang="ts">
    import {defineComponent} from 'vue';

    export default defineComponent({
        name: "Auth",
        setup() {
        }
    })
</script>