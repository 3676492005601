import {RouteRecordRaw, createRouter, createWebHistory} from 'vue-router';
import {userStore} from '@/store/user';

import {Translate} from "@/plugins/i18n";

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: 'dashboard',
        component: () => import('@/pages/Welcome.vue'),
        meta: {
            title: 'Dashboard',
            layout: 'Default',
            requiresAuthentication: true
        }
    },
    {
        path: '/create-campaign',
        name: 'create-campaign',
        component: () => import('@/pages/campaigns/CampaignFlow.vue'),
        meta: {
            title: 'Create campaign',
            layout: 'Default',
            requiresAuthentication: true
        }
    },
    {
        path: '/campaign/:id',
        name: 'edit-campaign',
        component: () => import('@/pages/campaigns/CampaignFlow.vue'),
        meta: {
            title: 'Edit campaign',
            layout: 'Default',
            requiresAuthentication: true
        }
    },
    {
        path: '/overview/:id',
        name: 'detail-campaign',
        component: () => import('@/pages/campaigns/CampaignDetail.vue'),
        meta: {
            title: 'Campaign overview',
            layout: 'Default',
            requiresAuthentication: true
        }
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('@/pages/authentication/Login.vue'),
        meta: {
            title: 'auth.login.title',
            layout: 'Auth',
            requiresAuthentication: false
        }
    },
    {
        path: '/register',
        name: 'register',
        component: () => import('@/pages/authentication/Registration.vue'),
        meta: {
            title: 'auth.registration.title',
            layout: 'Auth',
            requiresAuthentication: false
        }
    },
    {
        path: '/forgot-password',
        name: 'forgot-password',
        component: () => import('@/pages/authentication/ForgotPassword.vue'),
        meta: {
            title: 'auth.password.title',
            layout: 'Auth',
            requiresAuthentication: false
        }
    },
    {
        path: '/reset-password',
        name: 'reset-password',
        component: () => import('@/pages/authentication/ResetPassword.vue'),
        meta: {
            title: 'auth.reset.title',
            layout: 'Auth',
            requiresAuthentication: false
        }
    },
    {
        path: '/profile/company',
        name: 'profile',
        component: () => import('@/pages/profile/Company.vue'),
        meta: {
            title: 'auth.reset.title',
            layout: 'Default',
            requiresAuthentication: true
        }
    },
    {
        path: '/profile/brands',
        name: 'brands',
        component: () => import('@/pages/brands/Brands.vue'),
        meta: {
            title: 'brands.title',
            layout: 'Default',
            requiresAuthentication: true
        }
    },
    {
        path: '/profile/social-profiles',
        name: 'social',
        component: () => import('@/pages/profile/SocialProfiles.vue'),
        meta: {
            title: 'social.title',
            layout: 'Default',
            requiresAuthentication: true
        }
    },
    {
        path: '/profile/referrals',
        name: 'referrals',
        component: () => import('@/pages/profile/Referrals.vue'),
        meta: {
            title: 'pages.referrals',
            layout: 'Default',
            requiresAuthentication: true
        }
    },
    // {
    //     path: '/profile',
    //     name: 'profile',
    //     component: () => import('@/pages/Page.vue'),
    //     meta: {
    //         title: 'auth.user.profile',
    //         layout: 'Default',
    //         requiresAuthentication: true
    //     },
    //     redirect: {name: 'company'},
    //     children: [
    //         {
    //             path: 'company',
    //             name: 'company',
    //             component: () => import('@/pages/profile/Company.vue'),
    //             meta: {
    //                 title: 'company.title',
    //                 layout: 'Default',
    //                 requiresAuthentication: true
    //             },
    //         }, {
    //             path: 'brands',
    //             name: 'brands',
    //             component: () => import('@/pages/brands/Brands.vue'),
    //             meta: {
    //                 title: 'brands.title',
    //                 layout: 'Default',
    //                 requiresAuthentication: true
    //             },
    //         }, {
    //             path: 'social-profiles',
    //             name: 'social-profiles',
    //             component: () => import('@/pages/profile/SocialProfiles.vue'),
    //             meta: {
    //                 title: 'social.title',
    //                 layout: 'Default',
    //                 requiresAuthentication: true
    //             },
    //         },
    //         {
    //             path: 'referrals',
    //             name: 'referrals',
    //             component: () => import('@/pages/profile/Referrals.vue'),
    //             meta: {
    //                 title: 'pages.referrals',
    //                 layout: 'Default',
    //                 requiresAuthentication: true,
    //             },
    //         },
    //     ]
    // },
    {
        path: '/wallet',
        name: 'wallet',
        component: () => import('@/pages/wallet/Wallet.vue'),
        meta: {
            title: 'wallet.title',
            layout: 'Default',
            requiresAuthentication: true
        },
    },
    {
        path: '/support',
        name: 'support',
        component: () => import('@/pages/support/Support.vue'),
        meta: {
            title: 'support.title',
            layout: 'Default',
            requiresAuthentication: true
        },
    },
    {
        path: '/privacy-policy',
        name: 'privacy-policy',
        component: () => import('@/pages/PrivacyPolicy.vue'),
        meta: {
            title: 'pages.privacy-policy',
            layout: 'Default',
            requiresAuthentication: false,
            alwaysAvailable: true,
        },
    },
    {
        path: '/terms-of-service',
        name: 'terms-of-service',
        component: () => import('@/pages/TermsOfService.vue'),
        meta: {
            title: 'pages.terms-of-service',
            layout: 'Default',
            requiresAuthentication: false,
            alwaysAvailable: true,
        },
    },
]
const router = createRouter({
    history: createWebHistory(),
    routes
})

//  Check if user is authenticated for protected route
router.beforeEach((to, from, next) => {

    //  If route is protected and user is not authenticated
    if (!to.meta.alwaysAvailable && to.meta.requiresAuthentication && !userStore.getState().authenticated) {
        next({name: 'login'});
    }

    //  If user is authenticated and route is not protected
    if (!to.meta.alwaysAvailable && !to.meta.requiresAuthentication && userStore.getState().authenticated) {
        next({name: 'dashboard'});
    }

    next();
});

router.afterEach((to, from, next) => {
    const i18n = new Translate();
    document.title = '';
    if (to.meta.title) {
        document.title = i18n.translate(to.meta.title);
    }
});

export default router;

