<template>
    <FaqArticle v-model:visible="faqDrawer.visible" :id="faqDrawer.id" @all="showAll" />
    <FaqCategories v-model:visible="faqCategories.visible" />
</template>

<script lang="ts">
    import {defineComponent, reactive, watch} from 'vue';
    import {faqStore} from "@/store/faq";
    import FaqArticle from "@/pages/faq/FaqArticle.vue";
    import FaqCategories from "@/pages/faq/FaqCategories.vue";

    export interface IFaqDrawer {
        id: number | string,
        visible: boolean,
    }

    export default defineComponent({
        name: "Faq",
        components: {
            FaqArticle,
            FaqCategories
        },
        setup() {

            const faqCategories = reactive({
                visible: false,
            })

            const faqDrawer = reactive({
                visible: false,
                id: 0
            } as IFaqDrawer);

            watch(() => faqStore.getState(), (faq) => {
                faqDrawer.id = faq.id;
                faqDrawer.visible = faq.visible;
            }, {deep: true});

            watch(() => faqDrawer, (faq) => {
                if (!faq.visible) {
                    setTimeout(() => {
                        faqStore.hideArticle();
                    }, 500);
                }
            }, {deep: true})

            const showAll = () => {
                faqCategories.visible = true;
            }

            return {
                faqCategories,
                faqDrawer,
                showAll,
            }

        }
    })
</script>