
    import {defineComponent, reactive, watch} from 'vue';
    import {usePromise} from "@/components/usePromise";
    import {BaseColumn, BaseTable, Pagination, LinkAction} from "@/types/table";
    import {useFaq} from "@/plugins/faq";
    import {Article} from "@/interfaces/faq";

    export default defineComponent({
        name: "FaqCategories",
        props: {
            visible: {
                type: Boolean,
                default: false,
            },
            id: {
                type: [Number, String],
                default: 0
            }
        },
        emits: ['update:visible'],
        setup(props, {emit}) {

            const faq = useFaq();

            const faqDrawer = reactive(new usePromise());
            const show = async () => {
                const confirmed = await faqDrawer.show();
                emit('update:visible', false);
            }

            watch(() => props.visible, (visible) => {
                if (visible) {
                    show();
                }
            }, {immediate: true})

            const table = reactive(new BaseTable(Pagination.None));
            table.addColumn(new BaseColumn('title', 'faq.article.article'));
            table.addAction(new LinkAction('faq.article.read').onClick((row: Article) => faq.article(row.id)))

            const articles = reactive([
                {
                    title: '1234'
                }, {
                    title: '1234'
                }
            ]);

            return {
                faqDrawer,
                table,
                articles,
            }

        }
    })
