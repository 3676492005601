
    import {defineComponent, PropType, computed, watch, ref, Ref} from 'vue';
    import {Paging} from "@/interfaces/pagination";

    export default defineComponent({
        name: "Pagination",
        props: {
            pagination: {
                type: Object as PropType<Paging>,
                default: () => new Paging()
            },
            label: {
                type: String,
                default: 'Showing {from} to {to} of {total} results'
            },
            previousLabel: {
                type: String,
                default: 'Previous',
            },
            nextLabel: {
                type: String,
                default: 'Next',
            },
        },
        emits: ['on-page-changed'],
        setup(props, {emit}) {
            const prev = () => goto(props.pagination.current_page - 1)
            const next = () => goto(props.pagination.current_page + 1)
            const goto = (page: number) => {
                if (page > 0 && page <= props.pagination.last_page) {
                    emit('on-page-changed', page);
                }
            }
            const information = computed((): string => {
                return props.label
                    .replace(`{from}`, `<span class="font-medium">${props.pagination.from ?? ''}</span>`)
                    .replace(`{to}`, `<span class="font-medium">${props.pagination.to ?? ''}</span>`)
                    .replace(`{total}`, `<span class="font-medium">${props.pagination.total ?? ''}</span>`);
            });

            const pages: Ref<(number | null)[]> = ref([]);
            watch(() => props.pagination, (pagination) => {
                pages.value = [];


                if (props.pagination.last_page < 10) {
                    for (let i = 1; i <= props.pagination.last_page; i++) {
                        pages.value.push(i);
                    }
                } else {
                    if (props.pagination.current_page <= 3 || props.pagination.current_page > props.pagination.last_page - 3) {
                        for (let i = 1; i <= 4; i++) {
                            pages.value.push(i);
                        }
                        pages.value.push(null);
                        for (let i = props.pagination.last_page - 3; i <= props.pagination.last_page; i++) {
                            pages.value.push(i);
                        }
                    } else {
                        pages.value.push(1);
                        pages.value.push(null);
                        for (let i = props.pagination.current_page - 1; i <= props.pagination.current_page + 1; i++) {
                            pages.value.push(i);
                        }
                        pages.value.push(null);
                        pages.value.push(props.pagination.last_page);
                    }
                }
            }, {deep: true, immediate: true});

            return {
                prev,
                next,
                goto,
                pages,
                information,
            }
        }
    })
