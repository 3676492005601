import {BaseField, FieldType} from '@/types/field';
import {useDate} from '@/plugins/date';
import moment from "moment";

export enum FormValidation {
    AfterSubmit,
    Always,
    Immediately
}

export enum ValidationOperator {
    Equal,
    NotEqual,
    GreaterThen,
    GreaterThenOrEqualTo,
    LessThen,
    LessThenOrEqualTo,
}

export interface Rule {
    message: string,
    args: object,
    isValid: boolean,
    operator?: ValidationOperator,
    compare?: any,

    validate(field: BaseField, value: any): Promise<any>
}

export class BaseValidationRule implements Rule {
    message: string = '';
    args: object = {};
    isValid: boolean = true;
    operator?: ValidationOperator = ValidationOperator.Equal;
    compare?: any = null;

    public setMessage(message: string, args: object = {}): this {
        this.message = message;
        this.args = args;
        return this;
    }

    public validate(field: BaseField, value: any): Promise<any> {
        return new Promise((resolve, reject) => reject({message: this.message, args: this.args}));
    }
}

export class RequiredValidationRule extends BaseValidationRule {
    constructor() {
        super();
        this.setMessage('validation.required', {})
    }

    public validate(field: BaseField, value: any): Promise<any> {
        return new Promise((resolve, reject) => {
            switch (field.type) {
                case FieldType.Select:
                case FieldType.Radio:
                    this.isValid = field.options.map(option => option.value).indexOf(value) > -1;
                    break;
                case FieldType.Number :
                    this.isValid = !isNaN(value);
                    break;
                case FieldType.Stars :
                    this.isValid = !value || parseInt(value) > 0;
                    break;
                case FieldType.List :
                    this.isValid = value.length > 0;
                    break;
                default:
                    if (!value) {
                        this.isValid = false;
                    } else {
                        this.isValid = value.toString().length > 0;
                    }
                    break;
            }
            if (this.isValid) resolve();
            reject({message: this.message, args: this.args});
        });
    }
}

export class OperatorValidationRule extends BaseValidationRule {
    constructor(operator: ValidationOperator, compare: any) {
        super();
        this.operator = operator;
        this.compare = compare;
        switch (operator) {
            case ValidationOperator.Equal:
                this.setMessage('validation.operator.equal', {value: compare})
                break;
            case ValidationOperator.NotEqual:
                this.setMessage('validation.operator.not-equal', {value: compare})
                break;
            case ValidationOperator.GreaterThen:
                this.setMessage('validation.operator.greater-then', {value: compare})
                break;
            case ValidationOperator.GreaterThenOrEqualTo:
                this.setMessage('validation.operator.greater-then-or-equal-to', {value: compare})
                break;
            case ValidationOperator.LessThen:
                this.setMessage('validation.operator.less-then', {value: compare})
                break;
            case ValidationOperator.LessThenOrEqualTo:
                this.setMessage('validation.operator.less-then-or-equal-to', {value: compare})
                break;
        }
    }

    public validate(field: BaseField, value: any): Promise<any> {
        return new Promise((resolve, reject) => {
            let match = false;
            switch (this.operator) {
                case ValidationOperator.Equal:
                    match = value === this.compare;
                    break;
                case ValidationOperator.NotEqual:
                    match = value !== this.compare;
                    break;
                case ValidationOperator.GreaterThen:
                    match = value > this.compare;
                    break;
                case ValidationOperator.GreaterThenOrEqualTo:
                    match = value >= this.compare;
                    break;
                case ValidationOperator.LessThen:
                    match = value < this.compare;
                    break;
                case ValidationOperator.LessThenOrEqualTo:
                    match = value <= this.compare;
                    break;
            }
            this.isValid = match;
            if (this.isValid) resolve();
            reject({message: this.message, args: this.args});
        });
    }
}

export class EmailValidationRule extends BaseValidationRule {
    constructor() {
        super();
        this.setMessage('validation.email', {})
    }

    public validate(field: BaseField, value: any): Promise<any> {
        return new Promise((resolve, reject) => {
            const match = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            this.isValid = match.test(value) || value === '';
            if (this.isValid) resolve();
            reject({message: this.message, args: this.args});
        });
    }
}

export class UrlValidationRule extends BaseValidationRule {
    constructor() {
        super();
        this.setMessage('validation.url', {})
    }

    public validate(field: BaseField, value: any): Promise<any> {
        return new Promise((resolve, reject) => {
            const match = /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i;
            this.isValid = match.test(value) || value === '';
            if (this.isValid) resolve();
            reject({message: this.message, args: this.args});
        });
    }
}

export class MinLengthValidationRule extends BaseValidationRule {
    length: number = 0;
    singular: string = '';
    plural: string = '';

    constructor(length: number, singular?: string, plural?: string) {
        super();
        this.length = length;
        if(singular) {
            this.singular = singular;
        }
        if(plural) {
            this.plural = plural;
        }
        this.setMessage('validation.length', {length: length});
    }

    public validate(field: BaseField, value: any): Promise<any> {
        return new Promise((resolve, reject) => {
            switch (field.type) {
                case FieldType.Number :
                    this.setMessage(`validation.number`, {length: this.length});
                    this.isValid = value >= this.length;
                    break;
                case FieldType.Checkbox :
                case FieldType.SelectButton :
                    this.setMessage(`validation.checkbox.${this.length === 1 ? `one` : `multiple`}`, {
                        length: this.length,
                        singular: this.singular,
                        plural: this.plural,
                    });
                    this.isValid = (value as Array<any>).length >= this.length;
                    break;
                case FieldType.List :
                case FieldType.Tag :
                    this.setMessage(`validation.item.${this.length === 1 ? `one` : `multiple`}`, {
                        length: this.length,
                        singular: this.singular,
                        plural: this.plural,
                    });
                    this.isValid = (value as Array<any>).length >= this.length;
                    break;
                default:
                    this.isValid = value.toString().length >= this.length;
                    break;
            }
            if (this.isValid) resolve();
            reject({message: this.message, args: this.args});
        });
    }
}

export class MaxLengthValidationRule extends BaseValidationRule {
    length: number = 0;
    singular: string = '';
    plural: string = '';

    constructor(length: number, singular?: string, plural?: string) {
        super();
        this.length = length;
        if(singular) {
            this.singular = singular;
        }
        if(plural) {
            this.plural = plural;
        }
        this.setMessage('validation.max-length', {length: length});
    }

    public validate(field: BaseField, value: any): Promise<any> {
        return new Promise((resolve, reject) => {
            switch (field.type) {
                case FieldType.List :
                case FieldType.Tag :
                    this.setMessage(`validation.max-item.${this.length === 1 ? `one` : `multiple`}`, {
                        length: this.length,
                        singular: this.singular,
                        plural: this.plural,
                    });
                    this.isValid = (value as Array<any>).length <= this.length;
                    break;
                default:
                    this.isValid = value.toString().length <= this.length;
                    break;
            }
            if (this.isValid) resolve();
            reject({message: this.message, args: this.args});
        });
    }
}

export class MinDateValidationRule extends BaseValidationRule {
    min: Date | null = null;

    constructor(min: Date) {
        super();
        this.min = min;
        const date = useDate();
        this.setMessage('validation.date.min', {min: date.formatDate(this.min)});
    }

    public validate(field: BaseField, value: any): Promise<any> {
        return new Promise((resolve, reject) => {
            switch (field.type) {
                case FieldType.Date :
                    this.isValid = moment(value).toDate().getTime() >= moment(this.min as Date).startOf('day').toDate().getTime();
                    break;
                default:
                    this.isValid = true;
                    break;
            }
            if (this.isValid) resolve();
            reject({message: this.message, args: this.args});
        });
    }
}

export class MaxDateValidationRule extends BaseValidationRule {
    max: Date | null = null;

    constructor(max: Date) {
        super();
        this.max = max;
        const date = useDate();
        this.setMessage('validation.date.max', {max: date.formatDate(this.max)});
    }

    public validate(field: BaseField, value: any): Promise<any> {
        return new Promise((resolve, reject) => {
            switch (field.type) {
                case FieldType.Date :
                    this.isValid = moment(value).toDate().getTime() < moment(this.max as Date).startOf('day').toDate().getTime();
                    break;
                default:
                    this.isValid = true;
                    break;
            }
            if (this.isValid) resolve();
            reject({message: this.message, args: this.args});
        });
    }
}

export class BetweenValidationRule extends BaseValidationRule {
    min: number = 0;
    max: number = 0;

    constructor(min: number, max: number) {
        super();
        this.min = min;
        this.max = max;
        this.setMessage('validation.between', {min, max});
    }

    public validate(field: BaseField, value: any): Promise<any> {
        return new Promise((resolve, reject) => {
            switch (field.type) {
                case FieldType.Number :
                    this.isValid = value >= this.min && value <= this.max;
                    break;
                default:
                    this.isValid = true;
                    break;
            }
            if (this.isValid) resolve();
            reject({message: this.message, args: this.args});
        });
    }
}