
  import Auth from "@/layouts/Auth.vue";
  import Default from "@/layouts/Default.vue";
  import {defineComponent, computed} from 'vue';
  import { useRoute } from 'vue-router';
  import Faq from "@/pages/faq/Faq.vue";
  export default defineComponent({
    name: 'App',
    components: {
      Auth,
      Default,
      Faq,
    },
    setup() {
      const layout = computed(() => useRoute() ? useRoute().meta.layout : 'Default');
      return { layout };
    }
  })
