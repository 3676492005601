
    import {defineComponent, reactive, watch, computed} from 'vue';
    import {useRoute} from "vue-router";
    import {userStore} from '@/store/user';
    import router from '@/router';
    import {useRouterHelper} from "@/plugins/routerHelper";
    import {useNotification} from "@/plugins/notification";
    import {useTranslation} from "@/plugins/i18n";
    import {usePromise} from "@/components/usePromise";

    export default defineComponent({
        name: "Default",
        mounted() {
            window.addEventListener("resize", this.hideAllMenus);
        },
        beforeUnmount() {
            window.removeEventListener("resize", this.hideAllMenus);
        },
        setup() {

            const i18n = useTranslation();
            const notification = useNotification();
            const routeHelper = useRouterHelper();

            const logout = (event: any) => {
                hideAllMenus();
                event.stopPropagation();
                userStore.logout().then(() => {
                }).catch(() => {
                });
                router.push({name: 'login'});
            }

            let menu = reactive({
                profile: false,
                mobile: false,
                items: [{
                    caption: 'Dashboard',
                    routeName: 'dashboard'
                },
                    {
                    caption: 'Profile',
                    routeName: 'profile'
                },
                    {
                    caption: 'Wallet',
                    routeName: 'wallet'
                }, {
                    caption: 'Support',
                    routeName: 'support'
                }],
            });

            const hideAllMenus = () => {
                menu.profile = false;
                menu.mobile = false;
            }

            let profileMenu: any = null;

            const showProfileMenu = () => {
                if (profileMenu) {
                    clearInterval(profileMenu);
                }
                menu.profile = true;
            }

            const hideProfileMenu = () => {
                profileMenu = setInterval(() => {
                    menu.profile = false;
                }, 500);
            }

            const currentRoute = useRoute();
            watch(() => currentRoute, (currentRoute) => {
                hideAllMenus();
            }, {deep: true});


            //  Mobile menu
            const menuDrawer = reactive(new usePromise());
            const showMobileMenu = async () => {
                const confirmed = await menuDrawer.show();
                menu.mobile = false;
            };
            watch(() => menu.mobile, (mobile) => {
                if (mobile) {
                    showMobileMenu();
                } else {
                    if (menuDrawer.visible) {
                        menuDrawer.hide();
                    }
                }
            }, {immediate: true})


            //  Check if account is completed
            const banner = reactive({
                visible: false,
                icon: 'fal fa-rocket-launch',
                message: {
                    default: 'Please add some more details and you\'re ready to launch your first campaign',
                    mobile: 'Please review your account details.'
                },
                button: {
                    text: 'Complete your profile',
                    routeName: 'profile',
                }
            });
            setTimeout(() => {
                banner.visible = true;
            }, 2000);

            const authenticated = computed(() => userStore.getState().authenticated);

            switch (routeHelper.getQueryParameter('payment')) {
                case 'success' :
                    notification.success(i18n.translate('wallet.funds.success.title'), i18n.translate('wallet.funds.success.description'));
                    break;
                case 'cancel' :
                    notification.error(i18n.translate('wallet.funds.cancelled.title'), i18n.translate('wallet.funds.cancelled.description'));
                    break;
                default:
                    break;
            }

            const isActive = (item: any) => {
                return currentRoute.name === item.routeName;
            }

            return {
                logout,
                user: userStore.getState(),
                authenticated,
                hideAllMenus,
                showProfileMenu,
                hideProfileMenu,
                isActive,
                menu,
                currentRoute,
                banner,
                router,
                menuDrawer,
            }
        }
    })
