<template>
    <div class="bg-gray-50 px-4 py-3 sm:px-6 flex">
        <div class="flex-grow flex items-center">
            <transition
                    enter-active-class="ease-out duration-300"
                    enter-from-class="opacity-0"
                    enter-to-class="opacity-100"
                    leave-active-class="ease-out duration-200"
                    leave-from-class="opacity-100"
                    leave-to-class="opacity-0">
                <div class="text-gray-500" v-show="saving">
                    <i class="fal fa-spinner-third fa-spin p-2"></i>
                    <span class="text-sm leading-5 text-center pl-2">{{ text }}</span>
                </div>
            </transition>
        </div>
        <div class="flex flex-row-reverse">
            <span :class="{'flex ml-3 w-auto':true,'opacity-50':saving||disabled}">
                <slot/>
            </span>
        </div>
    </div>
</template>

<script lang="ts">
    import {defineComponent} from 'vue';

    export default defineComponent({
        name: "CardFooter",
        props: {
            saving: {
                type: Boolean,
                default: false
            },
            disabled: {
                type: Boolean,
                default: false
            },
            text: {
                type: String,
                default: '',
            }
        },
        setup(props) {
        }
    })
</script>