
    import {defineComponent, reactive, watch} from 'vue';
    import {usePromise} from "@/components/usePromise";
    import {BaseForm} from "@/types/form";
    import {TextareaField, TextField} from "@/types/field";
    import {MinLengthValidationRule, RequiredValidationRule} from "@/types/validation";
    import {getErrorMessage, useAxios} from "@/plugins/axios";
    import {useNotification} from "@/plugins/notification";
    import {useTranslation} from "@/plugins/i18n";

    export default defineComponent({
        name: "CreateTicket",
        props: {
            visible: {
                type: Boolean,
                default: false
            }
        },
        emits: ['update:visible', 'created'],
        setup(props, {emit}) {

            const axios = useAxios();

            const notification = useNotification();

            const i18n = useTranslation();

            const ticketDrawer = reactive(new usePromise());
            const show = async () => {
                const confirmed = await ticketDrawer.show();
                if (confirmed) {
                    form.setError('').submit().then(() => {
                        ticketDrawer.saving = true;
                        axios.post(`support`, values).then(() => {
                            notification.success(
                                i18n.translate('support.ticket.created.title'),
                                i18n.translate('support.ticket.created.description')
                            )
                            ticketDrawer.saving = false;
                            emit('created');
                            ticketDrawer.hide();
                        }).catch(error => {
                            ticketDrawer.saving = false;
                            form.setError(getErrorMessage(error.response.data));
                            show();
                        })
                    }).catch(error => {
                        ticketDrawer.saving = false;
                        form.setError(error);
                        show();
                    });
                } else {
                    emit('update:visible', false);
                }
            }

            watch(() => props.visible, (visible) => {
                if (visible) {
                    values.subject = '';
                    values.message = '';
                    show();
                }
            }, {immediate: true})

            const form = reactive(new BaseForm());
            form.addField(new TextField('subject', 'support.ticket.subject')
                .addRule(new RequiredValidationRule()));
            form.addField(new TextareaField('message', 'support.ticket.question')
                .setRows(5)
                .addRule(new RequiredValidationRule())
                .addRule(new MinLengthValidationRule(20)));

            const values = reactive({
                subject: '',
                message: '',
            });

            return {
                ticketDrawer,
                form,
                values,
            }

        }
    })
