import {Store} from "@/store/store";

export enum StackItemType {
    Modal,
    Drawer
}

export type StackItem = {
    uid: string,
    type: StackItemType
};

export class StackItemModel implements StackItem {
    uid: string = '';
    type: StackItemType = StackItemType.Modal;

    constructor(uid: string, type: StackItemType) {
        this.uid = uid;
        this.type = type;
    }
}

export type Stack = {
    list: StackItem[],
}

export class StackModel implements Stack {
    list: StackItem[] = []
}

class StackStore extends Store <Stack> {
    protected data(): Stack {
        return {
            list: [],
        };
    }

    constructor() {
        super();
    }

    register(uid: string, type: StackItemType): void {
        //  Prevent to register instance twice in stack
        if (this.last()?.uid != uid) {
            this.state.list.push(new StackItemModel(uid, type));
        }
    }

    position(uid: string): number {
        let pos = -1;
        this.state.list.forEach((item: StackItem, index: number) => {
            if (item.uid === uid) {
                pos = index;
            }
        });
        return pos;
    }

    index(uid: string, type: StackItemType) {
        const totalOfType = this.state.list.filter((item: StackItem) => item.type === type).length;
        const currentIndex = this.state.list.filter((item: StackItem) => item.type === type).map((item: StackItem) => item.uid).indexOf(uid);
        return totalOfType - currentIndex;
    }

    last(): StackItem | null {
        return this.state.list.length > 0 ? this.state.list[this.state.list.length - 1] : null;
    }

    remove(): void {
        this.state.list.splice(this.state.list.length - 1, 1);
    }
}

export const stackStore: StackStore = new StackStore();