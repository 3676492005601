<template>

    <Empty v-if="!table.loading && dataset.length === 0" :description="$translate(empty)">
        <template #image>
            <slot name="image">
                IMG
            </slot>
        </template>
        <slot name="empty"/>
    </Empty>
    <Loading :loading="table.loading"/>

    <div class="overflow-x-auto">
    <table :class="{'min-w-full divide-y divide-gray-200' : true, 'border-t border-gray-200' : borderTop}" v-if="!table.loading && dataset.length > 0">
        <thead>
        <tr>
            <th v-for="(column, index) in table.columns"
                :key="`column_${index}`"
                :class="{'px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider' : true, 'hidden md:table-cell': !column.mobile}">
                {{ column.translate ? $translate(column.caption) : column.caption }}
            </th>
            <th class="px-6 py-3 bg-gray-50" v-if="table.actions.length > 0"></th>
        </tr>
        </thead>
        <tbody class="bg-white divide-y divide-gray-200">
        <tr v-for="(row, index) in dataset" :key="`row_${index}`">
            <td v-for="(column, columnIndex) in table.columns"
                :key="`row_${index}_${columnIndex}`"
                :class="{'px-6 py-4 whitespace-no-wrap text-sm leading-5 font-medium text-gray-900': true, 'hidden md:table-cell': !column.mobile}">
                <slot :name="`column_${column.model}`" :value="row[column.model]" :row="row" :column="column">{{ row[column.model] }}</slot>
            </td>
            <td class="px-6 py-4 whitespace-no-wrap text-right text-sm leading-5 font-medium"
                v-if="table.actions.length > 0">
                <div class="hidden lg:block">
                    <span v-for="(group, groupIndex) in availableActions(row)" :key="`group_${groupIndex}`"
                          class="relative z-0 inline-flex ml-1">
                        <span v-for="(action, actionIndex) in group.actions"
                              :key="`action_${index}_${actionIndex}`">
                            <button type="button"
                                    v-if="action.type !== ActionType.Link"
                                    @click.prevent="click(action, row, index, $event)"
                                    :disabled="isDisabled(action, row)"
                                    :class="{
                                        'relative shadow-sm inline-flex items-center px-4 py-2 text-sm leading-5 font-medium border transition ease-in-out duration-150 focus:z-10 w-12 text-center focus:outline-none': true,
                                        'focus:ring-2 focus:ring-offset-2': group.actions.length === 1,
                                        'focus:ring-1': group.actions.length !== 1,
                                        'focus:border-custom-500': group.actions.length !== 1 && action.type !== ActionType.Delete,
                                        'focus:ring-custom-500': action.type !== ActionType.Delete,
                                        'border-gray-300 text-gray-700 bg-white': action.type === ActionType.Default,
                                        'hover:text-gray-500 focus:outline-none focus:border-custom-300 focus:shadow-outline-custom active:text-gray-800 active:bg-gray-50': action.type === ActionType.Default && !isDisabled(action, row),
                                        'border-transparent text-white bg-custom-600': action.type === ActionType.Primary,
                                        'hover:bg-custom-500 focus:outline-none focus:border-custom-700 focus:shadow-outline-custom active:bg-custom-700': action.type === ActionType.Primary && !isDisabled(action, row),
                                        'border-transparent bg-red-600 text-white focus:ring-red-500': action.type === ActionType.Delete,
                                        'focus:border-red-500': action.type === ActionType.Delete && group.actions.length !== 1,
                                        'hover:bg-red-500 focus:outline-none focus:border-red-700 focus:shadow-outline-red focus:ring-red-500': action.type === ActionType.Delete && !isDisabled(action, row),
                                        'rounded-md': group.actions.length === 1,
                                        'rounded-l-md': group.actions.length > 0 && actionIndex === 0,
                                        'rounded-r-md': group.actions.length > 0 && actionIndex === group.actions.length - 1,
                                        '-ml-px': actionIndex > 0,
                                        'opacity-50': isDisabled(action, row)
                                    }"
                                    v-tooltip="{text: action.translate ? $translate(action.caption) : action.caption}">
                                <i :class="`flex-1 ${action.icon}`"></i>
                            </button>
                            <a href="#" v-if="action.type === ActionType.Link" class="text-custom-600 hover:text-custom-900" @click.prevent="click(action, row, index, $event)">{{ action.translate ? $translate(action.caption) : action.caption }}</a>
                        </span>
                    </span>
                </div>
                <div class="relative flex justify-end items-center lg:hidden">
                    <button @click.prevent="showActionMenu(index)" @mouseleave="hideActionMenu" aria-has-popup="true"
                            type="button"
                            class="w-8 h-8 inline-flex items-center justify-center text-gray-400 rounded-full bg-transparent hover:text-gray-500 focus:outline-none focus:text-gray-500 focus:bg-gray-100 transition ease-in-out duration-150">
                        <svg class="w-5 h-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                            <path d="M10 6a2 2 0 110-4 2 2 0 010 4zM10 12a2 2 0 110-4 2 2 0 010 4zM10 18a2 2 0 110-4 2 2 0 010 4z"/>
                        </svg>
                    </button>
                    <transition
                            enter-active-class="transition ease-out duration-100"
                            enter-from-class="transform opacity-0 scale-95"
                            enter-to-class="transform opacity-100 scale-100"
                            leave-active-class="transition ease-in duration-75"
                            leave-from-class="transform opacity-100 scale-100"
                            leave-to-class="transform opacity-0 scale-95">
                        <div class="mx-3 origin-top-right absolute right-7 top-0 w-48 mt-1 rounded-md shadow-lg z-10"
                             @mouseenter="showActionMenu(index)" @mouseleave="hideActionMenu"
                             v-show="details.active === index">
                            <div class="z-10 rounded-md bg-white shadow-xs" role="menu" aria-orientation="vertical"
                                 aria-labelledby="project-options-menu-0">
                                <div :class="{'py-1':true,'border-t border-gray-100':groupIndex>0}"
                                     v-for="(group, groupIndex) in availableActions(row)" :key="`group_${groupIndex}`">
                                    <a href="#" @click.prevent="click(action, row, index, $event); details.active = -1"
                                       v-for="(action, actionIndex) in group.actions"
                                       :key="`action_${index}_${actionIndex}`"
                                       :class="{
                                           'group flex items-center px-4 py-2 text-sm leading-5 text-gray-700':true,
                                           'hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900': !isDisabled(action, row),
                                           'opacity-50': isDisabled(action, row)
                                       }"
                                       role="menuitem">
                                            <span :class="{'mr-3 h-5 w-5 text-gray-400':true,'group-hover:text-gray-500 group-focus:text-gray-500': !isDisabled(action, row)}">
                                                <i :class="action.icon"/>
                                            </span>
                                        {{ action.translate ? $translate(action.caption) : action.caption }}
                                    </a>
                                </div>
                            </div>
                        </div>
                    </transition>
                </div>
            </td>
        </tr>
        </tbody>
    </table>
    </div>
    <nav class="bg-white px-4 py-3 flex items-center justify-between border-t border-cool-gray-200 sm:px-6"
         v-if="table.pagination === Pagination.Simple && !table.loading && dataset.length > 0">
        <div class="hidden sm:block">
            <p class="text-sm leading-5 text-cool-gray-700">
                {{ $translate('table.results.showing') }}
                <span class="font-medium">{{ paging.start }}</span>
                {{ $translate('table.results.to') }}
                <span class="font-medium">{{ paging.end }}</span>
                {{ $translate('table.results.of') }}
                <span class="font-medium">{{ table.page.results }}</span>
                {{ $translate('table.results.results') }}
            </p>
        </div>
        <div class="flex-1 flex justify-between sm:justify-end">
            <a href="#" @click.prevent="previous"
               class="relative inline-flex items-center px-4 py-2 border border-cool-gray-300 text-sm leading-5 font-medium rounded-md text-cool-gray-700 bg-white hover:text-cool-gray-500 focus:outline-none focus:shadow-outline-custom focus:border-custom-300 active:bg-cool-gray-100 active:text-cool-gray-700 transition ease-in-out duration-150">
                {{ $translate('table.previous') }}
            </a>
            <a href="#" @click.prevent="next"
               class="ml-3 relative inline-flex items-center px-4 py-2 border border-cool-gray-300 text-sm leading-5 font-medium rounded-md text-cool-gray-700 bg-white hover:text-cool-gray-500 focus:outline-none focus:shadow-outline-custom focus:border-custom-300 active:bg-cool-gray-100 active:text-cool-gray-700 transition ease-in-out duration-150">
                {{ $translate('table.next') }}
            </a>
        </div>
    </nav>

    <div class="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6"
         v-if="table.pagination === Pagination.Numbers && !table.loading && dataset.length > 0">
        <div class="flex-1 flex justify-between sm:hidden">
            <a href="#" @click.prevent="previous"
               :class="{'relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:shadow-outline-custom focus:border-custom-300 active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150':true,
               'opacity-50':table.page.current === 1}">
                {{ $translate('table.previous') }}
            </a>
            <a href="#" @click.prevent="next"
               :class="{'ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:shadow-outline-custom focus:border-custom-300 active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150':true,
               'opacity-50':table.page.current === paging.pages}">
                {{ $translate('table.next') }}
            </a>
        </div>
        <div class="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
            <div>
                <p class="text-sm leading-5 text-gray-700 py-3">
                    {{ $translate('table.results.showing') }}
                    <span class="font-medium">{{ paging.start }}</span>
                    {{ $translate('table.results.to') }}
                    <span class="font-medium">{{ paging.end }}</span>
                    {{ $translate('table.results.of') }}
                    <span class="font-medium">{{ table.page.results }}</span>
                    {{ $translate('table.results.results') }}
                </p>
            </div>
            <div v-if="paging.pages > 1">
                <nav class="relative z-0 inline-flex shadow-sm">
                    <a href="#" @click.prevent="previous"
                       class="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm leading-5 font-medium text-gray-500 hover:text-gray-400 focus:z-10 focus:outline-none focus:border-custom-300 focus:shadow-outline-custom active:bg-gray-100 active:text-gray-500 transition ease-in-out duration-150"
                       aria-label="Previous">
                        <svg :class="{'h-5 w-5':true,'opacity-50':table.page.current === 1}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                            <path fill-rule="evenodd"
                                  d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                                  clip-rule="evenodd"/>
                        </svg>
                    </a>
                    <component :is="item > 0 ? 'a' : 'span'" href="#" @click.prevent="goto(item)"
                               v-for="(item, p) in paging.pagination"
                               :key="`key_${p}`"
                               :class="{
                           '-ml-px relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm leading-5 font-medium text-gray-700 transition ease-in-out duration-150' : true,
                           'hover:text-gray-500 focus:z-10 focus:outline-none focus:border-custom-300 focus:shadow-outline-custom active:bg-gray-100 active:text-gray-700': item > 0
                        }">
                        {{ item > 0 ? item : '...' }}
                    </component>
                    <a href="#" @click.prevent="next"
                       class="-ml-px relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm leading-5 font-medium text-gray-500 hover:text-gray-400 focus:z-10 focus:outline-none focus:border-custom-300 focus:shadow-outline-custom active:bg-gray-100 active:text-gray-500 transition ease-in-out duration-150"
                       aria-label="Next">
                        <svg :class="{'h-5 w-5':true,'opacity-50':table.page.current === paging.pages}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                            <path fill-rule="evenodd"
                                  d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                                  clip-rule="evenodd"/>
                        </svg>
                    </a>
                </nav>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
    import {defineComponent, PropType, reactive, ref, watch} from 'vue';
    import {ActionType, BaseAction, BaseActionGroup, Pagination, Table} from "@/types/table";

    export default defineComponent({
        name: "DataTable",
        props: {
            table: {
                type: Object as PropType<Table>,
                required: true,
            },
            dataset: {
                type: Array,
                default: () => []
            },
            empty: {
                type: String,
                default: 'table.empty'
            },
            borderTop: {
                type: Boolean,
                default: true,
            }
        },
        setup: function (props) {

            //  Table object
            const table = ref<Table>(props.table).value;

            //  Dataset
            const dataset = ref(props.dataset).value;

            //  Pagination previous
            const previous = () => {
                if (table.page.current > 1) {
                    table.gotoPage(table.page.current--);
                }
            }

            //  Pagination next
            const next = () => {
                if (table.page.current * table.page.perPage < table.page.results) {
                    table.gotoPage(table.page.current++);
                }
            }

            //  Pagination goto page
            const goto = (page: number) => {
                table.page.current = page;
                table.gotoPage(table.page.current);
            }

            //  Check if page is empty and not is first
            watch(() => dataset, (dataset) => {
                if (dataset.length === 0 && table.page.current > 1) {
                    goto(1);
                }
            }, {immediate: true, deep: true});

            const details = reactive({
                active: -1
            });

            let hideTimeout: any = null;
            const showActionMenu = (index: number) => {
                clearTimeout(hideTimeout);
                details.active = index;
            }
            const hideActionMenu = () => {
                hideTimeout = setTimeout(() => {
                    details.active = -1;
                }, 500);
            }


            //  Calculate items on current page
            const paginationNumber: number[] = [];
            let pages: number = 0;
            let start: number = 0;
            let end: number = 0;
            let paging = reactive({
                pagination: paginationNumber,
                pages,
                start,
                end
            });
            const updatePagination = () => {
                paging.pages = Math.ceil(table.page.results / table.page.perPage);
                paging.start = ((table.page.current - 1) * table.page.perPage) + 1;
                paging.end = table.page.current * table.page.perPage < table.page.results ? table.page.current * table.page.perPage : table.page.results;
                paging.pagination = []
                if (paging.pages <= 7) {
                    for (let i = 1; i <= paging.pages; i++) {
                        paging.pagination.push(i);
                    }
                } else {
                    if (table.page.current < 4) {
                        for (let i = 1; i <= 4; i++) {
                            paging.pagination.push(i);
                        }
                        paging.pagination.push(-1);
                        paging.pagination.push(paging.pages);
                    } else if (table.page.current > paging.pages - 3) {
                        paging.pagination.push(1);
                        paging.pagination.push(-1);
                        for (let i = paging.pages - 3; i <= paging.pages; i++) {
                            paging.pagination.push(i);
                        }
                    } else {
                        paging.pagination.push(1);
                        paging.pagination.push(-1);
                        for (let i = table.page.current - 1; i <= table.page.current + 1; i++) {
                            paging.pagination.push(i);
                        }
                        paging.pagination.push(-1);
                        paging.pagination.push(paging.pages);
                    }
                }
            }

            const isInvisible = (action: BaseAction, row: object): boolean => {
                return action.isInvisible(row);
            }

            const isDisabled = (action: BaseAction, row: object): boolean => {
                return action.isDisabled(row);
            }

            const click = (action: BaseAction, row: object, index: number, event: any): void => {
                event.target.blur();
                if (!isInvisible(action, row) || !isDisabled(action, row)) {
                    action.click(row, index);
                }
            }

            const availableActions = (row: object): Array<any> => {
                const actionGroups: BaseActionGroup[] = [];
                let lastGroupIndex: number | null = null;
                table.actions.forEach((actionGroup, groupIndex) => {
                    actionGroup.actions.forEach(action => {
                        if (!isInvisible((action as BaseAction), row)) {
                            if (lastGroupIndex != groupIndex) {
                                actionGroups.push(new BaseActionGroup());
                                lastGroupIndex = groupIndex;
                            }
                            (actionGroups[actionGroups.length - 1] as BaseActionGroup).addAction(action as BaseAction);
                        }
                    })

                })
                return actionGroups;
            };

            watch(() => table, (table) => {
                updatePagination();
            }, {immediate: true, deep: true});

            return {
                Pagination,
                ActionType,
                pages,
                start,
                end,
                goto,
                previous,
                next,
                details,
                paging,
                showActionMenu,
                hideActionMenu,
                isDisabled,
                isInvisible,
                click,
                availableActions,
            }
        }
    })
</script>