
    import {defineComponent, computed, reactive, watch} from 'vue';
    import {confirmStore} from "@/store/confirm";
    import {usePromise} from "@/components/usePromise";
    import {ConfirmType} from '@/store/confirm';

    export default defineComponent({
        name: "Confirm",
        setup() {

            //  Get confirm state from store
            const confirm = computed(() => confirmStore.getState());

            let style = reactive({
                icon: '',
                color: 'red'
            });

            const modal = reactive(new usePromise());
            const showConfirm = async () => {
                if (await modal.show()) {
                    confirmStore.confirmed();
                    setTimeout(() => {
                        modal.hide();
                    }, 50);
                } else {
                    confirmStore.rejected();
                }
            }

            //  Watch visibility change
            watch(() => confirm, (confirm) => {
                if (confirm.value.visible) {
                    showConfirm();
                }
                switch (confirm.value.type) {
                    case ConfirmType.Info:
                        style.icon = 'fal fa-info-circle';
                        style.color = 'custom';
                        break;
                    case ConfirmType.Warning:
                        style.icon = '';
                        style.color = 'orange';
                        break;
                    case ConfirmType.Error:
                        style.icon = 'fal fa-exclamation-triangle';
                        style.color = 'red';
                        break;

                }
            }, {
                immediate: true,
                deep: true
            })

            return {
                style,
                confirm,
                modal
            }

        }
    })
