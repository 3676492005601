<template>
  <div>
    <div class="sm:hidden px-4 sm:px-6 pt-4">
      <select @change="setTab($event)"
              class="block w-full block form-select w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:shadow-outline-custom focus:border-custom-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5">
        <option v-for="(tab, index) in tabs.tabs" :key="`tab_${index}`" :value="tab.uid"
                :selected="index === tabs.current">{{ tab.name }}
        </option>
      </select>
    </div>
    <div class="hidden sm:block border-b border-gray-200">
      <nav class="-mb-px flex space-x-8 px-4 sm:px-6" aria-label="Tabs">
        <a href="#"
           @click.prevent="set(tab.uid)"
           v-for="(tab, index) in tabs.tabs" :key="`tab_${index}`"
           :class="{
                       'group inline-flex items-center py-4 px-1 font-medium text-sm': true,
                       'border-b-2 border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300': index !== tabs.current,
                       'border-b-2 border-custom-500 text-custom-600': index === tabs.current,
                   }">
                    <span :class="{
                        '-ml-0.5 mr-2 h-5 w-5 hidden sm:block':true,
                        'text-gray-400 group-hover:text-gray-500': index !== tabs.current,
                        'text-custom-500': index === tabs.current,
                    }" v-if="tab.icon">
                        <i :class="tab.icon"/>
                    </span>
          <span>{{ tab.name }}</span>
          <span :class="{
                    'hidden ml-3 py-0.5 px-2.5 rounded-full text-xs font-medium md:inline-block':true,
                    'bg-gray-100 text-gray-900':index !== tabs.current,
                    'bg-custom-100 text-custom-600':index === tabs.current,
                  }" v-if="tab.badge > 0">{{ tab.badge > 99 ? '99+' : tab.badge }}</span>
        </a>
      </nav>
    </div>
    <slot/>
  </div>
</template>

<script lang="ts">
import {defineComponent, provide, reactive, watchEffect} from 'vue';
import {BaseTabs} from "@/types/tabs";

export default defineComponent({
  name: "Tabs",
  props: {
    modelValue: {
      type: Number,
      default: 0
    }
  },
  emits: ['update:modelValue'],
  setup(props, {emit}) {
    const tabs = reactive(new BaseTabs());
    provide('tabs', tabs);
    const set = (step: string) => tabs.set(step);
    const setTab = (event: any) => tabs.set(event.target.value);
    watchEffect(() => {
      emit('update:modelValue', tabs.current);
    });
    watchEffect(() => {
      const visibleTabs = tabs.tabs.filter(tab => tab.visible);
      if (props.modelValue in visibleTabs) {
        set(visibleTabs[props.modelValue].uid);
      }
    });
    return {
      tabs,
      set,
      setTab
    }
  }
})
</script>