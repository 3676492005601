<template>
    <div class="max-w-7xl mx-auto sm:px-6 lg:px-8">
        <div class="grid grid-cols-4 gap-4" v-show="details.overview && overview">
            <div class="col-span-4">
                <Card :hide-shadow-mobile="true">
                    <CardHeader :name="title" :description="description">
                        <div class="mt-6 grid grid-cols-1 gap-5 sm:gap-6">
                            <a href="#" @click.prevent="details.overview = false"
                               class="flex shadow-sm rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-custom-500"
                               v-for="(step, index) in steps.steps.filter(step => step.visible)"
                               :key="`step_${index}`">
                                <div class="flex-shrink-0 flex items-center justify-center w-16 bg-custom-500 text-white text-sm font-medium rounded-l-md">
                                    <i :class="step.icon" v-if="step.icon"/>
                                    <span v-if="!step.icon">4</span>
                                </div>
                                <div class="flex-1 flex items-center justify-between border-t border-r border-b border-gray-200 bg-white rounded-r-md truncate">
                                    <div class="flex-1 px-4 py-2 text-sm truncate">
                                        <p class="text-gray-900 font-medium hover:text-gray-600">{{ step.name
                                            }}</p>
                                        <p class="text-gray-500">{{ step.description }}</p>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div class="mt-6">
                            <button @click.prevent="details.overview = false" type="button"
                                    class="py-2 px-4 border w-full border-gray-300 rounded-md text-sm leading-5 font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:border-custom-300 focus:shadow-outline-custom active:bg-gray-50 active:text-gray-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-custom-500">
                                {{ start === 'form.start' ?
                                $translate(start) : start}}
                            </button>
                        </div>
                    </CardHeader>
                </Card>
            </div>
        </div>
        <div class="grid grid-cols-4 gap-4" v-show="(!details.overview && overview) || (details.overview && !overview)">
            <div class="col-span-4 md:col-span-3">
                <Card>
                    <nav class="block md:hidden flex items-center justify-center pt-6" aria-label="Progress"
                         v-if="steps.steps.filter(step => step.visible).length > 3">
                        <p class="text-sm font-medium">Step {{ (steps.current + 1) }} of {{ steps.steps.filter(step =>
                            step.visible).length }}</p>
                        <ol class="ml-8 flex items-center space-x-5">
                            <li v-for="(step, index) in steps.steps.filter(step => step.visible)"
                                :key="`step_${index}`">
                                <a href="#" @click.prevent="set(step.uid)"
                                   class="block w-2.5 h-2.5 bg-custom-600 rounded-full hover:bg-custom-900"
                                   v-if="index < steps.current">
                                    <span class="sr-only">Step {{(index+1)}}</span>
                                </a>
                                <a href="#" @click.prevent="set(step.uid)"
                                   class="relative flex items-center justify-center"
                                   v-if="index === steps.current">
                                    <span class="absolute w-5 h-5 p-px flex" aria-hidden="true">
                                        <span class="w-full h-full rounded-full bg-custom-200"></span>
                                    </span>
                                    <span class="relative block w-2.5 h-2.5 bg-custom-600 rounded-full"
                                          aria-hidden="true"></span>
                                    <span class="sr-only">Step {{(index+1)}}</span>
                                </a>
                                <a href="#" @click.prevent="set(step.uid)"
                                   class="block w-2.5 h-2.5 bg-gray-200 rounded-full hover:bg-gray-400"
                                   v-if="index > steps.current">
                                    <span class="sr-only">Step {{(index+1)}}</span>
                                </a>
                            </li>
                        </ol>
                    </nav>
                    <nav class="block md:hidden" v-if="steps.steps.filter(step => step.visible).length <= 3">
                        <ul class="border-b border-gray-300 flex">
                            <li class="relative flex-1 flex"
                                v-for="(step, index) in steps.steps.filter(step => step.visible)"
                                :key="`step_${index}`">
                                <a href="#" @click.prevent="set(step.uid)"
                                   class="group flex items-center w-full grid grid-col-1 grid-row-2">
                                    <div class="pt-4 flex items-center text-sm leading-5 font-medium my-0 mx-auto">
                                        <div v-if="steps.current > index"
                                             class="flex-shrink-0 w-6 h-6 flex items-center justify-center bg-custom-600 rounded-full group-hover:bg-custom-800 transition ease-in-out duration-150 text-white">
                                            <i class="fal fa-check"></i>
                                        </div>

                                        <div v-if="steps.current === index"
                                             class="flex-shrink-0 w-6 h-6 flex items-center justify-center border border-custom-600 rounded-full">
                                            <p class="text-custom-600">{{ index + 1}}</p>
                                        </div>

                                        <div v-if="steps.current < index"
                                             class="flex-shrink-0 w-6 h-6 flex items-center justify-center border border-gray-300 rounded-full group-hover:border-gray-400 transition ease-in-out duration-150">
                                            <span class="text-gray-500 group-hover:text-gray-900 transition ease-in-out duration-150">{{ index + 1}}</span>
                                        </div>
                                    </div>
                                    <div class="text-center pt-2 pb-3">
                                        <p v-if="steps.current > index"
                                           class="text-sm leading-5 font-medium text-gray-900">{{ step.name }}</p>
                                        <p v-if="steps.current === index"
                                           class="text-sm leading-5 font-medium text-custom-600">{{ step.name }}</p>
                                        <p v-if="steps.current < index"
                                           class="text-sm leading-5 font-medium text-gray-500 group-hover:text-gray-900 transition ease-in-out duration-150">
                                            {{ step.name }}</p>
                                    </div>
                                </a>
                                <div class="absolute top-0 right-0 h-full w-5 -mr-2"
                                     v-if="index < steps.steps.filter(step => step.visible).length - 1">
                                    <svg class="h-full w-full text-gray-300" viewBox="0 0 22 80" fill="none"
                                         preserveAspectRatio="none">
                                        <path d="M0 -2L20 40L0 82" vector-effect="non-scaling-stroke"
                                              stroke="currentcolor" stroke-linejoin="round"/>
                                    </svg>
                                </div>
                            </li>
                        </ul>
                    </nav>
                    <slot/>
                    <CardFooter :saving="steps.loading" :disabled="loading" :text="$translate(saving)">
                        <CardFooterButton v-if="current && steps.current > 0 && current.previous"
                                          :text="$translate(current.previous)" :primary="false"
                                          @click.prevent="previous"/>
                        <CardFooterButton v-if="current" :text="$translate(current.next)" @click.prevent="next"/>
                    </CardFooter>
                </Card>
            </div>
            <div class="hidden md:block">
                <div class="pt-24 mt-2 pl-4 sm:pl-6 lg:pl-8">
                    <slot name="before"/>
                    <nav class="flex">
                        <ul :class="{'space-y-6':true,'opacity-50':loading}">
                            <li v-for="(step, index) in steps.steps.filter(step => step.visible)"
                                :key="`step_${index}`">
                                <a href="#" class="group" @click.prevent="set(step.uid)">
                                    <div class="flex items-start space-x-3" v-if="index < steps.current">
                                        <div class="flex-shrink-0 relative h-5 w-5 flex items-center justify-center">
                                            <svg class="h-full w-full text-custom-600 group-hover:text-custom-800 group-focus:text-custom-800 transition ease-in-out duration-150"
                                                 xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                                                 fill="currentColor">
                                                <path fill-rule="evenodd"
                                                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                                      clip-rule="evenodd"></path>
                                            </svg>
                                        </div>
                                        <p class="text-sm leading-5 font-medium text-gray-500 group-hover:text-gray-900 group-focus:text-gray-900 transition ease-in-out duration-150">
                                            {{ step.name }}</p>
                                    </div>
                                    <div class="flex items-start space-x-3" v-if="index === steps.current">
                                        <div class="flex-shrink-0 relative h-5 w-5 flex items-center justify-center">
                                            <span class="absolute h-4 w-4 rounded-full bg-custom-200"></span>
                                            <span class="relative block w-2 h-2 bg-custom-600 rounded-full"></span>
                                        </div>
                                        <p class="text-sm leading-5 font-medium text-custom-600">{{ step.name }}</p>
                                    </div>
                                    <div class="flex items-start space-x-3" v-if="index > steps.current">
                                        <div class="flex-shrink-0 h-5 w-5 relative flex items-center justify-center">
                                            <span class="block h-2 w-2 bg-gray-300 rounded-full group-hover:bg-gray-400 group-focus:bg-gray-400 transition ease-in-out duration-150"></span>
                                        </div>
                                        <p class="text-sm leading-5 font-medium text-gray-500 group-hover:text-gray-900 group-focus:text-gray-900 transition ease-in-out duration-150">
                                            {{ step.name }}
                                        </p>
                                    </div>
                                </a>
                            </li>
                        </ul>
                    </nav>
                    <slot name="after"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import {defineComponent, provide, reactive, computed, watch, watchEffect} from 'vue';
    import {BaseSteps, Step} from '@/types/steps';
    import {prop} from "vue-class-component";

    export default defineComponent({
        name: "Steps",
        props: {
            loading: {
                type: Boolean,
                default: false,
            },
            overview: {
                type: Boolean,
                default: false,
            },
            title: {
                type: String,
                default: '',
            },
            description: {
                type: String,
                default: '',
            },
            start: {
                type: String,
                default: 'form.start',
            }
        },
        emits: ['change'],
        setup(props, {emit, slots}) {
            const details = reactive({
                overview: true,
            });
            const steps = reactive(new BaseSteps());
            provide('steps', steps);
            const current = computed(() => steps.steps.filter((step) => step.visible)[steps.current]);
            const saving = computed(() => steps.steps.length > 0 ? (current.value as Step).saving || 'loading.loading' : 'loading.loading');
            watch(() => current, (current) => {
                emit('change', steps.current);
            }, {immediate: true, deep: true});
            const next = () => {
                if (!props.loading) {
                    steps.next();
                }
            }
            const previous = () => {
                if (!props.loading) {
                    steps.previous();
                }
            }
            const set = (step: string) => {
                if (!props.loading) {
                    steps.set(step);
                }
            }
            return {
                details,
                current,
                saving,
                steps,
                next,
                previous,
                set
            }
        }
    })
</script>