
    import {defineComponent, PropType} from 'vue';

    export default defineComponent({
        name: "Header",
        props: {
            name: {
                type: String,
                default: '',
            },
            subtitle: {
                type: String,
                default: '',
            },
            previous: {
                type: String,
                default: ''
            }
        },
        emits: {
            onPrevious: null
        },
        setup(props, {slots}) {
            return {
                hasSlot: !!slots.default
            }
        }
    })
