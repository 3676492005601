import {ref} from "vue";
import {stackStore} from "@/store/stack";

export interface IPromise {
    visible: any;
    resolveFn: any;
    saving: boolean;
    show(): Promise<boolean>;
    response(value: boolean): void;
}

export class usePromise implements IPromise {

    visible: any = false;
    resolveFn: any = null;
    saving: boolean = false;

    constructor() {
        this.visible = ref(false);
    }

    show(): Promise<boolean> {
        this.visible = true;
        return new Promise((resolve) => {
            this.resolveFn = resolve;
        });
    }

    hide() {
        stackStore.remove();
        this.visible = false;
        this.saving = false;
        this.resolveFn(false);
    }

    response(value: boolean) {
        if(!value) {
            this.hide();
        }
        else {
            this.saving = true;
        }
        this.resolveFn(value);
    }

}
