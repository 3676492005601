import {reactive} from 'vue';
import ls from 'local-storage';
import {Store} from './store';
import {$axios, getErrorMessage} from "@/plugins/axios";
import router from "@/router";
import {appStore} from "@/store/app";

export type SocialProfiles = {
    facebook: string,
    instagram: string,
    youtube: string
}

export class SocialProfilesModel implements SocialProfiles {
    facebook: string = '';
    instagram: string = '';
    youtube: string = '';
}

export type Company = {
    company_name: string,
    address: string,
    postal_code: string,
    city: string,
    country: string,
    contact_person: string,
    email: string,
    website: string,
}

export class CompanyModel implements Company {
    address: string = '';
    city: string = '';
    contact_person: string = '';
    country: string = '';
    email: string = '';
    company_name: string = '';
    postal_code: string = '';
    website: string = '';
}

export type User = {
    expired: boolean,
    authenticated: boolean,
    name: string,
    email: string,
    token: string,
    company: Company,
    social: SocialProfiles,
    locale: string,
    date: string,
    datetime: string,
    referral: string,
}

export class UserModel implements User {
    expired: boolean = false;
    authenticated: boolean = false;
    email: string = '';
    name: string = '';
    token: string = '';
    company: Company = new CompanyModel();
    social: SocialProfiles = new SocialProfilesModel();
    locale: string = 'en-US'; // Should be an BCP 47 language code
    date: string = 'MM/DD/YYYY';
    datetime: string = 'MM/DD/YYYY hh:mm';
    referral: string = '';
}

class UserStore extends Store <User> {
    protected data(): User {
        return {
            expired: false,
            authenticated: false,
            name: '',
            email: '',
            token: '',
            company: new CompanyModel(),
            social: new SocialProfilesModel(),
            locale: 'en-US',
            date: 'MM/DD/YYYY',
            datetime: 'MM/DD/YYYY hh:mm',
            referral: '',
        };
    }

    constructor() {
        super();
        //  Load user state from local storage
        this.state = reactive(Object.assign(new UserModel, ls('user')));
    }

    isCompanyProfileComplete(): boolean {
        return this.state.company.company_name !== '' &&
            this.state.company.address !== '' &&
            this.state.company.postal_code !== '' &&
            this.state.company.city !== '' &&
            this.state.company.country !== '' &&
            this.state.company.contact_person !== '' &&
            this.state.company.email !== '' &&
            this.state.company.website !== ''
    }

    isSocialProfileComplete(): boolean {
        return this.state.social.youtube !== '' ||
            this.state.social.instagram !== '' ||
            this.state.social.facebook !== ''
    }

    updateCompanyProfile(company_name: string, address: string, postal_code: string, city: string, country: string, email: string, website: string): Promise<any> {
        return new Promise((resolve, reject) => {
            $axios.put(`company-details`, {
                company_name,
                address,
                postal_code,
                city,
                country,
                contact_person: this.state.name,
                email,
                website
            })
                .then(response => response.data)
                .then(response => {
                    const company = Object.assign(new CompanyModel(), {
                        company_name,
                        address,
                        postal_code,
                        city,
                        country,
                        email,
                        website,
                        contact_person: this.state.name
                    });
                    this.saveUser(this.state.name, this.state.email, this.state.referral, company, this.state.social, this.state.token);
                    resolve(response);
                })
                .catch(error => reject(getErrorMessage(error.response.data)));
        });
    }

    forgotPassword(email: string): Promise<any> {
        return new Promise((resolve, reject) => {
            $axios.put(`auth/user/forgot-password`, {email}).then(() => resolve()).catch(error => reject(getErrorMessage(error.response.data)));
        });
    }

    resetPassword(token: string, email: string, password: string, password_confirmation: string): Promise<any> {
        return new Promise((resolve, reject) => {
            $axios.put(`auth/user/reset-password`, {token, email, password, password_confirmation}).then(() => resolve()).catch(error => reject(getErrorMessage(error.response.data)));
        });
    }

    updateSocialProfiles(facebook: string, instagram: string, youtube: string): Promise<any> {
        return new Promise((resolve, reject) => {
            $axios.put(`social-profiles`, {facebook, instagram, youtube})
                .then(response => response.data)
                .then(response => {
                    const social = Object.assign(new SocialProfilesModel(), {facebook, instagram, youtube});
                    this.saveUser(this.state.name, this.state.email, this.state.referral, this.state.company, social, this.state.token);
                    resolve(response);
                })
                .catch(error => reject(getErrorMessage(error.response.data)));
        })
    }

    login(email: string, password: string) {
        return new Promise((resolve, reject) => {
            $axios.post(`auth/user/login`, {email, password})
                .then(response => response.data)
                .then(response => {
                    const company = new CompanyModel();
                    this.saveUser(response.user.name, response.user.email, response.user.referral_link,response.user.company_details || new CompanyModel(), response.user.social_profiles || new SocialProfilesModel(), response.access_token).then(() => {
                        this.profile().then(() => resolve()).catch(() => reject())
                    }).catch(() => reject())
                })
                .catch(error => reject(getErrorMessage(error.response.data)));
        });
    }

    logout(expired : boolean = false) {
        return new Promise((resolve, reject) => {

            //  Check if user is on a login page
            const isLoginPage = router.currentRoute.value.name === 'login';

            //  Create empty user object
            const user = reactive(new UserModel());

            //  Set user expired
            user.expired = isLoginPage ? false : expired;

            //  Update local storage
            ls('user', user);

            //  Update store state
            this.state = user;

            if(isLoginPage) {
                reject();
            }

            //  Resolve
            resolve();
        });
    }

    saveUser(name: string, email: string, referral: string, company: Company, social: SocialProfiles, token?: string): Promise<any> {

        //  Create new user model
        const user = new UserModel();
        if (token) {
            user.expired = false;
            user.authenticated = true;
            user.name = name;
            user.email = email
            user.token = token;
            user.company = company;
            user.social = social;
            user.referral = referral;
        }

        //  Save user in local storage
        ls('user', user);

        //  Update store state with current user
        this.state = user;

        return Promise.resolve();
    }

    profile(): Promise<any> {
        return new Promise((resolve, reject) => {
            Promise.all([
                appStore.fetchConfig(),
                appStore.fetchCountries(),
                appStore.fetchBrands(),
            ])
                .then(() => resolve())
                .catch(error => {
                    alert(error);
                    reject(error)
                })
        })
    }

    register(name: string, email: string, password: string, referral: string): Promise<any> {
        return new Promise((resolve, reject) => {
            $axios.post(`auth/user/register`, {name, email, password, referral})
                .then(response => response.data)
                .then(response => {
                    const company = new CompanyModel();
                    const social = new SocialProfilesModel();
                    this.saveUser(response.user.name, response.user.email, response.user.referral_link, company, social, response.access_token).then(() => {
                        this.profile().then(() => resolve()).catch(() => reject())
                    }).catch(() => reject())
                })
                .catch(error => reject(getErrorMessage(error.response.data)));
        })
    }

    updateKeyValue(key: string, value: any) {
        const user: { [key: string]: any } = Object.assign(new UserModel(), ls('user'));
        user[key] = value;
        // @ts-ignore
        this.state[key] = value;
        ls('user', this.state);
    }

}

export const userStore: UserStore = new UserStore();