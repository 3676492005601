export enum LogicMethod {
    Show,
    Hide
}

export enum LogicMatch {
    Any,
    All
}

export enum LogicRuleOperator {
    Is,
    IsNot
}

export interface ILogic {
    operator: LogicMethod,
    match: LogicMatch,
    rules: LogicRule[],
}

export interface ILogicRule {
    model: string;
    operator: LogicRuleOperator;
    value: any;
}

export class Logic implements ILogic {
    match: LogicMatch = LogicMatch.Any;
    operator: LogicMethod = LogicMethod.Hide;
    rules: ILogicRule[] = [];

    setMatch(match: LogicMatch): Logic {
        this.match = match;
        return this;
    }

    setOperator(operator: LogicMethod): Logic {
        this.operator = operator;
        return this;
    }

    addRule(rule: LogicRule): Logic {
        this.rules.push(rule);
        return this;
    }
}

export class LogicRule implements ILogicRule {
    model: string = '';
    operator: LogicRuleOperator = LogicRuleOperator.Is;
    value: any = '';

    constructor(model: string, operator: LogicRuleOperator, value: any) {
        this.model = model;
        this.operator = operator;
        this.value = value;
    }
}