<template>
    <teleport to="body" @keyup.esc="closeActiveDrawer">
        <div class="fixed inset-0 overflow-hidden" v-if="active.shown" :style="`z-index: ${position + 30};`">
            <div class="absolute inset-0 overflow-hidden">
                <transition
                        enter-active-class="ease-out duration-300"
                        enter-from-class="opacity-0"
                        enter-to-class="opacity-100"
                        leave-active-class="ease-out duration-200"
                        leave-from-class="opacity-100"
                        leave-to-class="opacity-0">
                    <div class="fixed inset-0 transition-opacity" v-show="active.visible" @click="cancelDrawer">
                        <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
                    </div>
                </transition>
                <section
                        :class="`absolute inset-y-0 lg:pl-16 max-w-full flex transition-all ease-in-out duration-500 ${right}`">

                    <transition
                            enter-active-class="transform transition ease-in-out duration-500 sm:duration-700"
                            enter-from-class="translate-x-full"
                            enter-to-class="translate-x-0"
                            leave-active-class="transform transition ease-in-out duration-500 sm:duration-700"
                            leave-from-class="translate-x-0"
                            leave-to-class="translate-x-full">
                        <div :class="`w-screen ${width}`" v-show="active.visible">
                            <div class="h-full flex flex-col bg-white shadow-xl overflow-y-scroll">
                                <slot name="header">
                                    <header class="space-y-1 py-6 px-4 bg-custom-700 sm:px-6">
                                        <div class="flex items-center justify-between space-x-3">
                                            <h2 class="text-lg leading-7 font-medium text-white">
                                                {{ title }}
                                            </h2>
                                            <div class="h-7 flex items-center">
                                                <button aria-label="Close panel"
                                                        @click="cancelDrawer"
                                                        class="text-custom-200 hover:text-white transition ease-in-out duration-150">
                                                    <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg"
                                                         fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                        <path stroke-linecap="round" stroke-linejoin="round"
                                                              stroke-width="2" d="M6 18L18 6M6 6l12 12"/>
                                                    </svg>
                                                </button>
                                            </div>
                                        </div>
                                        <div v-if="description">
                                            <p class="text-sm leading-5 text-custom-300">
                                                {{ description }}
                                            </p>
                                        </div>
                                    </header>
                                </slot>
                                <div :class="`relative flex-1 ${bg}`">
                                    <slot/>
                                </div>
                                <slot name="footer">
                                    <div class="flex flex-shrink-0 px-4 border-t border-gray-200 py-5 sm:px-6">
                                        <div class="flex-grow flex items-center">
                                            <transition
                                                    enter-active-class="ease-out duration-300"
                                                    enter-from-class="opacity-0"
                                                    enter-to-class="opacity-100"
                                                    leave-active-class="ease-out duration-200"
                                                    leave-from-class="opacity-100"
                                                    leave-to-class="opacity-0">
                                                <div class="text-gray-500" v-show="drawer.saving">
                                                    <i class="fal fa-spinner-third fa-spin p-2"></i>
                                                    <span class="text-sm leading-5 text-center pl-2">{{ $translate(saving) }}</span>
                                                </div>
                                            </transition>
                                        </div>
                                        <div class="flex flex-row-reverse">
                                            <div class="space-x-3 flex justify-end">
                                                <span class="inline-flex rounded-md shadow-sm">
                                                    <button @click.prevent="cancelDrawer" type="button"
                                                            :class="{'py-2 px-4 border border-gray-300 rounded-md text-sm leading-5 font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:border-custom-300 focus:shadow-outline-custom active:bg-gray-50 active:text-gray-800 transition duration-150 ease-in-out':true,'opacity-50':drawer.saving}">
                                                        {{ $translate('form.cancel') }}
                                                    </button>
                                                </span>
                                                <span class="inline-flex rounded-md shadow-sm">
                                                    <button @click.prevent="saveDrawer" type="submit"
                                                            :class="{'inline-flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-custom-600 hover:bg-custom-500 focus:outline-none focus:border-custom-700 focus:shadow-outline-custom active:bg-custom-700 transition duration-150 ease-in-out':true,'opacity-50':drawer.saving}">
                                                        {{ save ? save : $translate('form.save') }}
                                                    </button>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </slot>
                            </div>
                        </div>
                    </transition>
                </section>
            </div>
        </div>
    </teleport>
</template>

<script lang="ts">
    import {computed, defineComponent, PropType, reactive, watch, ref} from 'vue';
    import {IPromise, usePromise} from "@/components/usePromise";
    import useUid from "@/components/useUid";
    import {StackItemType, stackStore} from "@/store/stack";
    import {cancel, cancelActive, confirm} from "@/components/useModal";

    export default defineComponent({
        name: "Drawer",
        props: {
            drawer: {
                type: Object as PropType<IPromise>,
            },
            title: {
                type: String,
                default: ''
            },
            description: {
                type: String,
                default: ''
            },
            width: {
                type: String,
                default: 'max-w-md'
            },
            bg: {
                type: String,
                default: 'bg-white'
            },
            saving: {
                type: String,
                default: 'loading.saving',
            },
            save: {
                type: String,
                default: ''
            }
        },
        mounted() {
            window.addEventListener('keyup', this.closeActiveDrawer)
        },
        unmounted() {
            window.removeEventListener('keyup', this.closeActiveDrawer)
        },
        setup(props) {

            //  Get unique id
            const {uid} = useUid();

            //  Active state of drawer
            let active = reactive({shown: false, visible: false});

            //  Watch for change of visible property
            watch(() => props.drawer, (drawer) => {
                const visible = Object.assign(usePromise, drawer).visible;

                //  Register component in stack if becomes visible
                if (visible) {
                    stackStore.register(uid, StackItemType.Drawer);
                    active.shown = true;
                } else {
                    setTimeout(() => active.shown = false, 500);
                }
                setTimeout(() => {
                    active.visible = visible;
                }, visible ? 25 : 0);
            }, {immediate: true, deep: true});

            const closeActiveDrawer = (event: any) => {
                if (event.key == 'Escape') {
                    cancelActive(props.drawer as usePromise, uid);
                }
            }

            //  Get stack position
            const position = computed(() => stackStore.position(uid));
            const index = computed(() => stackStore.index(uid, StackItemType.Drawer));
            const right = computed(() => index.value <= 1 ? 'right-0' : index.value === 2 ? 'right-24' : 'right-48');

            const drawer = ref(props.drawer).value;

            const cancelDrawer = () => {
                if (!(drawer as usePromise).saving) {
                    cancel(props.drawer as usePromise, '');
                }
            }

            const saveDrawer = () => {
                if (!(drawer as usePromise).saving) {
                    confirm(props.drawer as usePromise, '');
                }
            }

            return {
                active,
                position,
                right,
                cancelDrawer,
                saveDrawer,
                closeActiveDrawer,
            }
        }
    })
</script>