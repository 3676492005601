<template>
    <div class="fixed inset-0 flex items-end justify-center px-4 py-6 pointer-events-none sm:p-6 sm:items-start sm:justify-end" :style="`z-index: ${stack};`">
        <div class="max-w-sm w-full pointer-events-auto">
            <div
                    v-for="(notification, index) in notifications"
                    :key="`notification_${index}`">
                <transition
                        enter-active-class="transform transition ease-in-out duration-500"
                        enter-from-class="-translate-y-24"
                        enter-to-class="translate-y-0"
                        leave-active-class="transform transition ease-in-out duration-500"
                        leave-from-class="opacity-100"
                        leave-to-class="opacity-0">
                    <div v-show="notification.visible">
                        <div class="rounded-lg shadow-xs overflow-hidden bg-white shadow-lg rounded-lg mb-4">
                            <div class="p-4">
                                <div class="flex items-start">
                                    <div class="flex-shrink-0">
                                        <span class="h-6 w-6 text-green-400 text-xl" v-if="notification.type === NotificationType.Success">
                                            <i class="fal fa-check-circle"></i>
                                        </span>
                                        <span class="h-6 w-6 text-red-400 text-xl" v-if="notification.type === NotificationType.Error">
                                            <i class="fal fa-exclamation-triangle"></i>
                                        </span>
                                        <span class="h-6 w-6 text-custom-400 text-xl" v-if="notification.type === NotificationType.Info">
                                            <i class="fal fa-info-circle"></i>
                                        </span>
                                    </div>
                                    <div class="ml-3 w-0 flex-1 pt-0.5">
                                        <p class="text-sm leading-5 font-medium text-gray-900">
                                            {{ notification.title }}
                                        </p>
                                        <p class="mt-1 text-sm leading-5 text-gray-500" v-if="notification.message">
                                            {{ notification.message }}
                                        </p>
                                    </div>
                                    <div class="ml-4 flex-shrink-0 flex">
                                        <button class="inline-flex text-gray-400 focus:outline-none focus:text-gray-500 transition ease-in-out duration-150 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-custom-500" @click="notification.hide()">
                                            <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                                <path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd" />
                                            </svg>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </transition>
            </div>
        </div>
    </div>


</template>

<script lang="ts">
    import {computed, defineComponent} from 'vue';
    import {notificationStore} from '@/store/notification';
    import {stackStore} from '@/store/stack';
    import {NotificationType} from '@/store/notification';

    export default defineComponent({
        name: "Notification",
        setup() {

            //  Get notification state from store
            const notifications = computed(() => notificationStore.getState());

            const stack = 50 + stackStore.getState().list.length;

            return {
                NotificationType,
                notifications: notifications.value.notifications,
                stack
            }

        }
    })
</script>