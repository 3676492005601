import {inject} from "vue";
import {faqStore} from "@/store/faq";

export class Faq {
    article(id: string | number, showRaiseTicketButton: boolean = false) {
        faqStore.showArticle(id, showRaiseTicketButton);
    }
}

export const FaqSymbol = Symbol();

export function useFaq() {
    const confirm = inject(FaqSymbol);
    if (!confirm) throw new Error('Plugin not injected');
    return new Faq();
}

export default {
    install: (app: any) => {
        const Plugin = new Faq();
        app.config.globalProperties.$faq = Plugin;
        app.provide(FaqSymbol, Plugin)
    }
}