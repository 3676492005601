
    import {defineComponent} from 'vue';

    export default defineComponent({
        name: "Empty",
        props: {
            description: {
                type: String,
                default: '',
            }
        },
        setup(props, {slots}) {
            return {
                hasSlot: !!slots.default
            }
        }
    })
