<template>
    <div class="lg:flex lg:items-center lg:justify-between">
        <div class="flex-1 min-w-0">
            <h2 class="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:leading-9 sm:truncate">
                {{ name }}
            </h2>
            <nav class="pt-2" v-if="previous">
                <a @click.prevent="$emit('on-previous')" href="#" class="flex items-center text-sm leading-5 font-medium text-gray-500 hover:text-gray-400 transition duration-150 ease-in-out">
                    <span class="text-sm pr-2">
                        <i class="far fa-chevron-left"></i>
                    </span>
                    {{ previous }}
                </a>
            </nav>
            <nav class="pt-2" v-if="!previous">
                <span class="flex items-center text-sm leading-5 font-medium text-gray-500 transition duration-150 ease-in-out">
                    {{ subtitle === '' ? '&nbsp;' : subtitle }}
                </span>
            </nav>
        </div>
        <div class="mt-5 flex lg:mt-0 lg:ml-4" v-if="hasSlot">
            <slot />
        </div>
    </div>
</template>

<script lang="ts">
    import {defineComponent, PropType} from 'vue';

    export default defineComponent({
        name: "Header",
        props: {
            name: {
                type: String,
                default: '',
            },
            subtitle: {
                type: String,
                default: '',
            },
            previous: {
                type: String,
                default: ''
            }
        },
        emits: {
            onPrevious: null
        },
        setup(props, {slots}) {
            return {
                hasSlot: !!slots.default
            }
        }
    })
</script>