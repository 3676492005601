import confetti from 'canvas-confetti';

class Party {
    protected end: Date | Number = new Date();

    public shootConfetti(particleCount: number) {
        return confetti({
            particleCount: particleCount,
            spread: 70,
            origin: {y: 0.6}
        });
    }

    private prideLeft() {
        let count = 0;
        const int = setInterval(() => {
            count++;
            confetti({
                particleCount: 2,
                angle: 60,
                spread: 55,
                origin: {x: 0},
                colors: ['#6d0294', '#4029b8', '#0a5ae4']
            });
            if(count > 100) {
                clearInterval(int);
            }
        }, 10)
    }

    private prideRight() {
        let count = 0;
        const int = setInterval(() => {
            count++;
            confetti({
                particleCount: 2,
                angle: 120,
                spread: 55,
                origin: { x: 1 },
                colors: ['#6d0294', '#4029b8', '#0a5ae4']
            });
            if(count > 100) {
                clearInterval(int);
            }
        }, 10)
    }

    public pride() {
        this.prideLeft();
        setTimeout(() => this.prideRight(), 750);
    }

}

export const ConfettiSymbol = Symbol();

export function useConfetti() {
    return new Party();
}

export default {
    install: (app: any) => {
        app.provide(ConfettiSymbol, new Party())
    }
}
