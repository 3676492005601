import {usePromise} from "@/components/usePromise";
import {stackStore} from "@/store/stack";

export const cancelActive = (promise: usePromise, uid: string) => {
    //  Retrieve last element in stack
    const lastInStack = stackStore.last();

    //  Check if stack has an last element
    if(!lastInStack) return;

    //  Check if last stack element is this modal instance
    if(lastInStack.uid !== uid) return;

    //  Close this modal
    cancel(promise, uid);
}

export const cancel = (promise: usePromise, uid: string) => {
    //  Return promise reject
    promise.response(false);
}

export const confirm = (promise: usePromise, uid: string) => {
    //  Return promise resolve
    promise.response(true);
}