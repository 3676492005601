import {reactive} from 'vue';
import {Store} from './store';

export type Faq = {
    id: string | number,
    visible: boolean,
    raiseTicketButton: boolean,
}

export class FaqModel implements Faq {
    id: string | number = 0;
    visible: boolean = false;
    raiseTicketButton: boolean = false;
}

class FaqStore extends Store <Faq> {
    protected data(): Faq {
        return {
            id: 0,
            visible: false,
            raiseTicketButton: false,
        };
    }

    constructor() {
        super();
        this.state = reactive(new FaqModel());
    }

    resolveId(id: number | string) {
        const mapping = require('../pages/faq/mapping.json');
        return mapping[id] ?? id;
    }

    showArticle(id: number | string, showRaiseTicketButton: boolean) {
        this.state.id = this.resolveId(id);
        this.state.visible = true;
        this.state.raiseTicketButton = showRaiseTicketButton;
    }

    hideArticle() {
        this.state.id = 0;
        this.state.visible = false;
        this.state.raiseTicketButton = false;
    }

}

export const faqStore: FaqStore = new FaqStore();