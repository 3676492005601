import {inject} from "vue";
import router from '@/router';

export class routerHelper {

    public getParameters(): { [key: string]: any } {
        return router.currentRoute.value.params;
    }

    public getParameter(parameter: string): string {
        if (Object.keys(this.getParameters()).indexOf(parameter) > -1) {
            return this.getParameters()[parameter];
        }
        return '';
    }

    public hasParameter(parameter: string): boolean {
        return Object.keys(this.getParameters()).indexOf(parameter) > -1;
    }

    public getQuery(): { [key: string]: any } {
        return router.currentRoute.value.query;
    }

    public getQueryParameter(parameter: string): string {
        if (Object.keys(this.getQuery()).indexOf(parameter) > -1) {
            return this.getQuery()[parameter];
        }
        return '';
    }

    public hasQueryParameter(parameter: string): boolean {
        return Object.keys(this.getQuery()).indexOf(parameter) > -1;
    }

}

export const RouterHelperSymbol = Symbol();

export function useRouterHelper() {
    const notification = inject(RouterHelperSymbol);
    if (!notification) throw new Error('Plugin not injected');
    return new routerHelper();
}

export default {
    install: (app: any, options: any) => {
        app.provide(RouterHelperSymbol, new routerHelper())
    }
}