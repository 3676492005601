import {Field} from "@/types/field";
import {FormValidation} from "@/types/validation";

export interface Tab {
    uid: string,
    name: string,
    icon: string,
    visible: boolean,
    badge: number,
}

export class BaseTab implements Tab {
    uid: string = ''
    name: string = ''
    icon: string = ''
    visible: boolean = true
    badge: number = 0

    constructor(uid: string, name: string, icon: string) {
        this.uid = uid;
        this.name = name;
        this.icon = icon;
        this.visible = true;
    }

    setBadge(badge: number): this {
        this.badge = badge;
        return this;
    }
}

export interface Tabs {
    tabs: Tab[]
    current: number
}

export class BaseTabs implements Tabs {
    tabs: Tab[] = [];
    current: number = 0;

    constructor() {
        this.tabs = [];
        this.current = 0;
    }

    public set(tab: string): void {
        this.current = this.tabs.map(tab => tab.uid).indexOf(tab);
    }
}