import {Store} from "@/store/store";
import useUid from '@/components/useUid';

export enum NotificationType {
    Message,
    Info,
    Success,
    Error
}

export type Notifications = {
    notifications: Notification[]
}

export type Notification = {
    uid: string,
    visible: boolean,
    type: NotificationType,
    duration: number,
    title: string,
    message?: string,
    show(): void,
    hide(): void,
}

export class NotificationModal implements Notification {
    uid: string = '';
    visible: boolean = false;
    type: NotificationType = NotificationType.Success;
    duration: number = 4000;
    title: string = '';
    message?: string = '';
    show(): void {}
    hide(): void {}

    constructor() {
        this.uid = useUid().uid;
        setTimeout(() => {
            this.show();
        }, 50);
    }
}

class NotificationStore extends Store <Notifications> {

    protected data(): Notifications {
        return {
            notifications: [],
        };
    }

    constructor() {
        super();
    }

    show(modal: NotificationModal): void {
        modal.visible = false;
        setTimeout(() => {
            modal.hide();
        }, modal.duration)
        this.state.notifications.unshift(modal);
    }

    delete(modal: NotificationModal): void {
        this.state.notifications.splice(this.state.notifications.map((notification: Notification) => notification.uid).indexOf(modal.uid), 1);
    }
}

export const notificationStore: NotificationStore = new NotificationStore();