
    import {computed, defineComponent, PropType, reactive, watch, ref} from 'vue';
    import {IPromise, usePromise} from "@/components/usePromise";
    import useUid from "@/components/useUid";
    import {StackItemType, stackStore} from "@/store/stack";
    import {cancel, cancelActive, confirm} from "@/components/useModal";

    export default defineComponent({
        name: "Drawer",
        props: {
            drawer: {
                type: Object as PropType<IPromise>,
            },
            title: {
                type: String,
                default: ''
            },
            description: {
                type: String,
                default: ''
            },
            width: {
                type: String,
                default: 'max-w-md'
            },
            bg: {
                type: String,
                default: 'bg-white'
            },
            saving: {
                type: String,
                default: 'loading.saving',
            },
            save: {
                type: String,
                default: ''
            }
        },
        mounted() {
            window.addEventListener('keyup', this.closeActiveDrawer)
        },
        unmounted() {
            window.removeEventListener('keyup', this.closeActiveDrawer)
        },
        setup(props) {

            //  Get unique id
            const {uid} = useUid();

            //  Active state of drawer
            let active = reactive({shown: false, visible: false});

            //  Watch for change of visible property
            watch(() => props.drawer, (drawer) => {
                const visible = Object.assign(usePromise, drawer).visible;

                //  Register component in stack if becomes visible
                if (visible) {
                    stackStore.register(uid, StackItemType.Drawer);
                    active.shown = true;
                } else {
                    setTimeout(() => active.shown = false, 500);
                }
                setTimeout(() => {
                    active.visible = visible;
                }, visible ? 25 : 0);
            }, {immediate: true, deep: true});

            const closeActiveDrawer = (event: any) => {
                if (event.key == 'Escape') {
                    cancelActive(props.drawer as usePromise, uid);
                }
            }

            //  Get stack position
            const position = computed(() => stackStore.position(uid));
            const index = computed(() => stackStore.index(uid, StackItemType.Drawer));
            const right = computed(() => index.value <= 1 ? 'right-0' : index.value === 2 ? 'right-24' : 'right-48');

            const drawer = ref(props.drawer).value;

            const cancelDrawer = () => {
                if (!(drawer as usePromise).saving) {
                    cancel(props.drawer as usePromise, '');
                }
            }

            const saveDrawer = () => {
                if (!(drawer as usePromise).saving) {
                    confirm(props.drawer as usePromise, '');
                }
            }

            return {
                active,
                position,
                right,
                cancelDrawer,
                saveDrawer,
                closeActiveDrawer,
            }
        }
    })
