import {translationStore} from '@/store/i18n';
import {inject} from "vue";


export class Translate {
    translate(key: string, args?: object) {
        //  Key parts
        const parts = key.split('.');

        //  Check if parts
        if(parts.length === 0) return key;

        try {
            //  Translations
            const translations = require('../locales/' + translationStore.getState().language + '/' + parts[0] + '.json');

            //  Find translation
            let translation = translations;
            parts.forEach(function(part: string, index: number) {
                if (index > 0 && typeof translation[part] !== 'undefined') {
                    translation = translation[part];
                }
            });

            //  Replace { element } with args value
            let result = typeof translation === 'string' ? translation : key;
            for(let argKey in args) {
                // @ts-ignore
                result = result.replace('{' + argKey + '}', args[argKey]);
            }
            return result;
        }
        catch (e) {
            console.error(e.message);
            return key;
        }
    }
}

export const TranslateSymbol = Symbol();

export function useTranslation() {
    const notification = inject(TranslateSymbol);
    if (!notification) throw new Error('Plugin not injected');
    return new Translate();
}

export default {
    install: (app: any, options: any) => {
        if (!options) options = {};
        const Plugin = new Translate();
        app.config.globalProperties.$translate = Plugin.translate;
        app.provide(TranslateSymbol, Plugin)
    }
}