<template>
    <div v-if="loading">
        <div class="text-62l text-center pt-10">
            <i class="fal fa-spinner-third fa-spin"></i>
        </div>
        <div class="text-sm leading-5 text-gray-500 pb-10 pt-4 text-center">
            {{ text || $translate('loading.loading') }}
        </div>
    </div>
</template>

<script lang="ts">
    import {defineComponent} from 'vue';

    export default defineComponent({
        name: "Loading",
        props: {
            loading: {
                type: Boolean,
                default: false
            },
            text: {
                type: String,
                default: ''
            }
        },
        setup() {
        }
    })
</script>