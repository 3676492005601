import {Notification, NotificationSymbol} from "@/plugins/notification";

export interface TooltipOptions {
    text: string
}

export class Tooltip {
    constructor(element: any) {
    }
}

export default {
    install: (app: any, options: any) => {
        if (!options) options = {};
        app.directive('tooltip', {
            mounted (el: any, binding: any, vnode: any, oldVnode: any) {

                const tooltip = (binding.value as TooltipOptions);

                el.tooltip = new Tooltip(el);
                // some logic ...
                // console.log('Tooltip', el, tooltip, vnode, oldVnode);
            },
            updated() {

            },
            unmounted() {

            }
        })
    }
}