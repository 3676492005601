<template>
    <Drawer :drawer="faqDrawer"
            :title="faq.loading ? $translate(`faq.article.loading`) : !faq.exists ? $translate(`faq.faq.title`) : faq.title"
            :description="$translate(`faq.article.title`)" width="max-w-3xl">
        <Loading :loading="faq.loading" v-if="faq.loading"/>
        <Empty :description="$translate(`faq.article.error`)" v-if="!faq.exists && !faq.loading">
            <template #image>
                <div class="pt-16 pb-8">
                    <img src="../../assets/images/tickets.svg" class="h-32 m-auto"/>
                </div>
            </template>
            <!--            <button type="button"-->
            <!--                    @click.prevent="showFaq"-->
            <!--                    class="inline-flex items-center mr-3 px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-custom-500">-->
            <!--                {{ $translate(`faq.category.show`) }}-->
            <!--            </button>-->
            <button type="button"
                    @click.prevent="createTicket"
                    class="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-custom-500">
                {{ $translate(`support.raise-a-ticket`) }}
            </button>
        </Empty>
        <div class="px-4 py-5 sm:p-6 prose" v-if="faq.exists && !faq.loading" v-html="faq.article"/>
        <template #footer>
            <div class="flex flex-shrink-0 px-4 border-t border-gray-200 py-5 sm:px-6"
                 v-if="store.raiseTicketButton && !faq.loading">
                <div class="flex-grow flex items-center">
                </div>
                <div class="flex flex-row-reverse">
                    <div class="space-x-3 flex justify-end">
                        <span class="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto">
                            <button type="button"
                                    @click.prevent="createTicket"
                                    :class="`inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-custom-600 hover:bg-custom-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-custom-500`">
                                {{ $translate(`support.raise-a-ticket`) }}
                            </button>
                        </span>
                    </div>
                </div>
            </div>
        </template>
    </Drawer>
    <CreateTicket v-model:visible="faq.create" @created="ticketCreated"/>
</template>

<script lang="ts">
    import {defineComponent, reactive, watch} from 'vue';
    import {usePromise} from "@/components/usePromise";
    import {useAxios} from "@/plugins/axios";
    import CreateTicket from "@/pages/support/CreateTicket.vue";
    import {faqStore} from "@/store/faq";

    export default defineComponent({
        name: "ViewFaqArticle",
        components: {
            CreateTicket,
        },
        props: {
            id: {
                type: [Number, String],
                default: '',
            },
            visible: {
                type: Boolean,
                default: false,
            }
        },
        emits: ['update:visible', 'all'],
        setup(props, {emit}) {

            const axios = useAxios();

            const faq = reactive({
                loading: false,
                exists: true,
                title: '',
                article: '',
                create: false,
            })

            const faqDrawer = reactive(new usePromise());
            const show = async () => {
                const confirmed = await faqDrawer.show();
                emit('update:visible', false);
            }

            const fetch = () => {
                faq.loading = true;
                axios.get(`faq/article/${props.id}`)
                    .then(response => response.data)
                    .then(response => {
                        faq.title = response.title;
                        faq.article = response.article;
                        faq.loading = false;
                        faq.exists = true;
                    }).catch(() => {
                    faq.loading = false;
                    faq.exists = false;
                });
            }

            watch(() => props.visible, (visible) => {
                if (visible) {
                    show();
                    fetch();
                }
            }, {immediate: true})

            const createTicket = () => {
                faq.create = true;
            }

            const ticketCreated = () => {
                faq.create = false;
                faqDrawer.hide();
            }

            const showFaq = () => {
                emit('all');
                faqDrawer.hide();
            }

            return {
                store: faqStore.getState(),
                faq,
                faqDrawer,
                createTicket,
                ticketCreated,
                showFaq
            }
        }
    })
</script>