import {uuid} from 'vue-uuid';

export default () => {

    //  Create unique component id
    const uid = 'uid-' + uuid.v4();

    return {
        uid
    };
}