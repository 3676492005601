import {Store} from './store';

export type Translation = {
    language: string,
}

class TranslationStore extends Store <Translation> {
    protected data(): Translation {
        return {
            language: 'en',
        };
    }

    language(language: string) {
        this.state.language = language;
    }
}

export const translationStore: TranslationStore = new TranslationStore();