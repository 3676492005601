
import {defineComponent, provide, reactive, computed, watch, watchEffect} from 'vue';
    import {BaseSteps, Step} from '@/types/steps';
    import {prop} from "vue-class-component";

    export default defineComponent({
        name: "Steps",
        props: {
            loading: {
                type: Boolean,
                default: false,
            },
            overview: {
                type: Boolean,
                default: false,
            },
            title: {
                type: String,
                default: '',
            },
            description: {
                type: String,
                default: '',
            },
            start: {
                type: String,
                default: 'form.start',
            }
        },
        emits: ['change'],
        setup(props, {emit, slots}) {
            const details = reactive({
                overview: true,
            });
            const steps = reactive(new BaseSteps());
            provide('steps', steps);
            const current = computed(() => steps.steps.filter((step) => step.visible)[steps.current]);
            const saving = computed(() => steps.steps.length > 0 ? (current.value as Step).saving || 'loading.loading' : 'loading.loading');
            watch(() => current, (current) => {
                emit('change', steps.current);
            }, {immediate: true, deep: true});
            const next = () => {
                if (!props.loading) {
                    steps.next();
                }
            }
            const previous = () => {
                if (!props.loading) {
                    steps.previous();
                }
            }
            const set = (step: string) => {
                if (!props.loading) {
                    steps.set(step);
                }
            }
            return {
                details,
                current,
                saving,
                steps,
                next,
                previous,
                set
            }
        }
    })
