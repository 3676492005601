
    import {defineComponent} from 'vue';

    export default defineComponent({
        name: "CardFooter",
        props: {
            saving: {
                type: Boolean,
                default: false
            },
            disabled: {
                type: Boolean,
                default: false
            },
            text: {
                type: String,
                default: '',
            }
        },
        setup(props) {
        }
    })
