
    import {computed, defineComponent} from 'vue';
    import {notificationStore} from '@/store/notification';
    import {stackStore} from '@/store/stack';
    import {NotificationType} from '@/store/notification';

    export default defineComponent({
        name: "Notification",
        setup() {

            //  Get notification state from store
            const notifications = computed(() => notificationStore.getState());

            const stack = 50 + stackStore.getState().list.length;

            return {
                NotificationType,
                notifications: notifications.value.notifications,
                stack
            }

        }
    })
