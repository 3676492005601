<template>
    <div :class="{
        'bg-white sm:rounded-lg overflow-hidden':true,
        'shadow':!hideShadowMobile,
        'sm:shadow':hideShadowMobile,
    }">
        <slot/>
    </div>
</template>

<script lang="ts">
    import {defineComponent} from 'vue';

    export default defineComponent({
        name: "Card",
        props: {
            hideShadowMobile: {
                type: Boolean,
                default: false,
            }
        },
        setup() {
        }
    })
</script>