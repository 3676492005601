
import {defineComponent, provide, reactive, watchEffect} from 'vue';
import {BaseTabs} from "@/types/tabs";

export default defineComponent({
  name: "Tabs",
  props: {
    modelValue: {
      type: Number,
      default: 0
    }
  },
  emits: ['update:modelValue'],
  setup(props, {emit}) {
    const tabs = reactive(new BaseTabs());
    provide('tabs', tabs);
    const set = (step: string) => tabs.set(step);
    const setTab = (event: any) => tabs.set(event.target.value);
    watchEffect(() => {
      emit('update:modelValue', tabs.current);
    });
    watchEffect(() => {
      const visibleTabs = tabs.tabs.filter(tab => tab.visible);
      if (props.modelValue in visibleTabs) {
        set(visibleTabs[props.modelValue].uid);
      }
    });
    return {
      tabs,
      set,
      setTab
    }
  }
})
