<template>
    <div>
        <slot name="image">
            <img src="../assets/images/empty.svg" class="h-32 m-auto mt-16 mb-8" />
        </slot>
        <div :class="{'text-sm leading-5 text-gray-500 text-center':true,'pb-12':!hasSlot,'pb-4':hasSlot}">
            {{ description }}
        </div>
        <div class="text-center pb-12 pt-4" v-if="hasSlot">
            <slot />
        </div>
    </div>
</template>

<script lang="ts">
    import {defineComponent} from 'vue';

    export default defineComponent({
        name: "Empty",
        props: {
            description: {
                type: String,
                default: '',
            }
        },
        setup(props, {slots}) {
            return {
                hasSlot: !!slots.default
            }
        }
    })
</script>