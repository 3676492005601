<template>
    <div class="min-h-screen bg-white">
        <nav class="bg-gray-800">
            <div class="max-w-7xl mx-auto px-2 sm:px-4 lg:px-8">
                <div class="relative flex items-center justify-between h-16">
                    <div class="flex items-center px-2 lg:px-0">
                        <div class="flex-shrink-0">
                            <img class="h-8 w-auto text-white" src="../assets/logos/brand-ambassador.svg">
                        </div>
                        <div class="hidden lg:block lg:ml-6">
                            <div class="flex" v-if="user.authenticated">
                                <router-link v-for="(item, index) in menu.items"
                                             :key="`menu_item_${index}`"
                                             tag="a"
                                             :to="{name:item.routeName}"
                                             :class="{
                                                 'px-3 py-2 rounded-md text-sm leading-5 font-medium transition duration-150 ease-in-out': true,
                                                 'text-white bg-gray-900 focus:outline-none focus:text-white focus:bg-gray-700': isActive(item),
                                                 'text-gray-300 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700': !isActive(item),
                                                 'ml-4' : index > 0
                                             }">
                                    {{ item.caption }}
                                </router-link>
                            </div>
                        </div>
                    </div>
                    <div class="flex-1 flex justify-center px-2 lg:ml-6 lg:justify-end">

                    </div>
                    <div class="flex lg:hidden" v-if="user.authenticated">
                        <button @click="menu.mobile = !menu.mobile"
                                class="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:bg-gray-700 focus:text-white transition duration-150 ease-in-out"
                                aria-label="Main menu" aria-expanded="false">
                            <svg :class="{'h-6 w-6': true, 'block': !menu.mobile, 'hidden': menu.mobile}"
                                 xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                 stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                      d="M4 6h16M4 12h16M4 18h16"/>
                            </svg>
                            <svg :class="{'h-6 w-6': true, 'block': menu.mobile, 'hidden': !menu.mobile}"
                                 xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                 stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                      d="M6 18L18 6M6 6l12 12"/>
                            </svg>
                        </button>
                    </div>
                    <div class="hidden lg:block lg:ml-4">
                        <div class="flex items-center">
                            <span class="ml-3 text-gray-400 text-sm">
                                {{ user.name }}
                            </span>
                            <div class="ml-4 relative flex-shrink-0" v-if="user.authenticated">
                                <button @mouseenter="showProfileMenu" @mouseleave="hideProfileMenu"
                                        class="flex-shrink-0 p-1 border-2 border-transparent text-gray-400 rounded-full hover:text-white focus:outline-none focus:text-white focus:bg-gray-700 transition duration-150 ease-in-out"
                                        aria-label="Notifications">
                                    <span class="h-6 w-6"><i class="far fa-user"></i></span>
                                </button>
                                <transition
                                        enter-active-class="transition ease-out duration-200"
                                        enter-class="transform opacity-0 scale-95"
                                        enter-to-class="transform opacity-100 scale-100"
                                        leave-active-class="transition ease-in duration-75"
                                        leave-class="transform opacity-100 scale-100"
                                        leave-to-class="transform opacity-0 scale-95">
                                    <div class="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg z-10"
                                         v-show="menu.profile"
                                         @mouseleave="hideProfileMenu"
                                         @mouseenter="showProfileMenu">
                                        <div class="py-1 rounded-md bg-white shadow-xs">
                                            <router-link :to="{name:'profile'}" tag="a"
                                                         class="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out">
                                                Your Profile
                                            </router-link>
                                            <a href="#" @click.prevent="logout($event)"
                                               class="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out">
                                                Sign out
                                            </a>
                                        </div>
                                    </div>
                                </transition>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </nav>
        <router-view></router-view>
    </div>
    <Drawer :drawer="menuDrawer" width="max-w-7xl">
        <template #header>
            <header class="space-y-1 py-4 px-4 bg-custom-700 sm:px-6">
                <div class="flex items-center justify-between space-x-3">
                    <h2 class="text-lg leading-7 font-medium text-white">
                        Menu
                    </h2>
                    <div class="h-7 flex items-center">
                        <button aria-label="Close panel"
                                @click="menuDrawer.hide()"
                                class="text-custom-200 hover:text-white transition ease-in-out duration-150">
                            <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg"
                                 fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round"
                                      stroke-width="2" d="M6 18L18 6M6 6l12 12"/>
                            </svg>
                        </button>
                    </div>
                </div>
            </header>
        </template>
        <div class="p-4">
            <nav class="space-y-1" aria-label="Sidebar">
                <router-link v-for="(item, index) in menu.items"
                             :key="`menu_item_${index}`"
                             tag="a"
                             :to="{name:item.routeName}"
                             :class="{
                                 'bg-gray-100 text-gray-900 group flex items-center px-3 py-2 text-sm font-medium rounded-md': currentRoute.name === item.routeName,
                                 'text-gray-600 hover:bg-gray-50 hover:text-gray-900 group flex items-center px-3 py-2 text-sm font-medium rounded-md': currentRoute.name !== item.routeName,
                                 'mt-1' : index > 0
                             }">
                    {{ item.caption }}
                </router-link>
            </nav>
        </div>
        <div class="p-4 border-t border-gray-200">
            <a href="#"
               class="text-gray-600 hover:bg-gray-50 hover:text-gray-900 group flex items-center px-3 py-2 text-sm font-medium rounded-md"
               role="menuitem" @click.prevent="logout($event)">
                Sign out
            </a>
        </div>
        <template #footer/>
    </Drawer>
</template>

<script lang="ts">
    import {defineComponent, reactive, watch, computed} from 'vue';
    import {useRoute} from "vue-router";
    import {userStore} from '@/store/user';
    import router from '@/router';
    import {useRouterHelper} from "@/plugins/routerHelper";
    import {useNotification} from "@/plugins/notification";
    import {useTranslation} from "@/plugins/i18n";
    import {usePromise} from "@/components/usePromise";

    export default defineComponent({
        name: "Default",
        mounted() {
            window.addEventListener("resize", this.hideAllMenus);
        },
        beforeUnmount() {
            window.removeEventListener("resize", this.hideAllMenus);
        },
        setup() {

            const i18n = useTranslation();
            const notification = useNotification();
            const routeHelper = useRouterHelper();

            const logout = (event: any) => {
                hideAllMenus();
                event.stopPropagation();
                userStore.logout().then(() => {
                }).catch(() => {
                });
                router.push({name: 'login'});
            }

            let menu = reactive({
                profile: false,
                mobile: false,
                items: [{
                    caption: 'Dashboard',
                    routeName: 'dashboard'
                },
                    {
                    caption: 'Profile',
                    routeName: 'profile'
                },
                    {
                    caption: 'Wallet',
                    routeName: 'wallet'
                }, {
                    caption: 'Support',
                    routeName: 'support'
                }],
            });

            const hideAllMenus = () => {
                menu.profile = false;
                menu.mobile = false;
            }

            let profileMenu: any = null;

            const showProfileMenu = () => {
                if (profileMenu) {
                    clearInterval(profileMenu);
                }
                menu.profile = true;
            }

            const hideProfileMenu = () => {
                profileMenu = setInterval(() => {
                    menu.profile = false;
                }, 500);
            }

            const currentRoute = useRoute();
            watch(() => currentRoute, (currentRoute) => {
                hideAllMenus();
            }, {deep: true});


            //  Mobile menu
            const menuDrawer = reactive(new usePromise());
            const showMobileMenu = async () => {
                const confirmed = await menuDrawer.show();
                menu.mobile = false;
            };
            watch(() => menu.mobile, (mobile) => {
                if (mobile) {
                    showMobileMenu();
                } else {
                    if (menuDrawer.visible) {
                        menuDrawer.hide();
                    }
                }
            }, {immediate: true})


            //  Check if account is completed
            const banner = reactive({
                visible: false,
                icon: 'fal fa-rocket-launch',
                message: {
                    default: 'Please add some more details and you\'re ready to launch your first campaign',
                    mobile: 'Please review your account details.'
                },
                button: {
                    text: 'Complete your profile',
                    routeName: 'profile',
                }
            });
            setTimeout(() => {
                banner.visible = true;
            }, 2000);

            const authenticated = computed(() => userStore.getState().authenticated);

            switch (routeHelper.getQueryParameter('payment')) {
                case 'success' :
                    notification.success(i18n.translate('wallet.funds.success.title'), i18n.translate('wallet.funds.success.description'));
                    break;
                case 'cancel' :
                    notification.error(i18n.translate('wallet.funds.cancelled.title'), i18n.translate('wallet.funds.cancelled.description'));
                    break;
                default:
                    break;
            }

            const isActive = (item: any) => {
                return currentRoute.name === item.routeName;
            }

            return {
                logout,
                user: userStore.getState(),
                authenticated,
                hideAllMenus,
                showProfileMenu,
                hideProfileMenu,
                isActive,
                menu,
                currentRoute,
                banner,
                router,
                menuDrawer,
            }
        }
    })
</script>