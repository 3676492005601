import {createApp} from 'vue'
import App from './App.vue'
import router from './router';

/**
 * PLUGINS
 */
import i18n from './plugins/i18n';
import confirm from './plugins/confirm';
import notification from './plugins/notification';
import axios from './plugins/axios';
import tooltip from "@/plugins/tooltip";
import faq from "@/plugins/faq";
import routerHelper from "@/plugins/routerHelper";
import stripe from "@/plugins/stripe";
import confetti from "@/plugins/confetti";
import clipboard from "@/plugins/clipboard";

/**
 * STYLING
 */
import './assets/tailwind.css'
import '../node_modules/@fortawesome/fontawesome-pro/css/all.css'

/**
 * GLOBAL COMPONENTS
 */
import Alert from "@/components/Alert.vue";
import Drawer from "@/components/Drawer.vue";
import Modal from "@/components/Modal.vue";
import DataForm from "@/components/DataForm.vue";
import Header from "@/components/Header.vue";
import ConfirmModal from '@/components/Confirm.vue';
import NotificationStack from '@/components/Notification.vue';
import Steps from '@/components/Steps.vue';
import Step from '@/components/Step.vue';
import Loading from '@/components/Loading.vue';
import Empty from '@/components/Empty.vue';
import DataTable from '@/components/DataTable.vue';
import Card from '@/components/Card.vue';
import CardHeader from '@/components/CardHeader.vue';
import CardFooter from '@/components/CardFooter.vue';
import CardFooterButton from '@/components/CardFooterButton.vue';
import Tabs from '@/components/Tabs.vue';
import Tab from '@/components/Tab.vue';
import Pagination from "@/components/Pagination.vue";

createApp(App)
    .use(router)
    .use(i18n)
    .use(confirm)
    .use(notification)
    .use(axios)
    .use(tooltip)
    .use(faq)
    .use(routerHelper)
    .use(stripe)
    .use(confetti)
    .use(clipboard)
    .component(NotificationStack.name, NotificationStack)
    .component(ConfirmModal.name, ConfirmModal)
    .component(Alert.name, Alert)
    .component(Drawer.name, Drawer)
    .component(Modal.name, Modal)
    .component(DataForm.name, DataForm)
    .component(Header.name, Header)
    .component(Steps.name, Steps)
    .component(Step.name, Step)
    .component(Loading.name, Loading)
    .component(Empty.name, Empty)
    .component(DataTable.name, DataTable)
    .component(Card.name, Card)
    .component(CardHeader.name, CardHeader)
    .component(CardFooter.name, CardFooter)
    .component(CardFooterButton.name, CardFooterButton)
    .component(Tabs.name, Tabs)
    .component(Tab.name, Tab)
    .component(Pagination.name, Pagination)
    .mount('#app')
