
    import {defineComponent} from 'vue';

    export default defineComponent({
        name: "Loading",
        props: {
            loading: {
                type: Boolean,
                default: false
            },
            text: {
                type: String,
                default: ''
            }
        },
        setup() {
        }
    })
