
    import {defineComponent} from 'vue';

    export default defineComponent({
        name: "Card",
        props: {
            hideShadowMobile: {
                type: Boolean,
                default: false,
            }
        },
        setup() {
        }
    })
