<template>
    <teleport to="body">
        <div class="fixed z-10 inset-0 overflow-y-auto" v-if="active.shown" :style="`z-index: ${position + 30};`">
            <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <transition
                        enter-active-class="ease-out duration-300"
                        enter-from-class="opacity-0"
                        enter-to-class="opacity-100"
                        leave-active-class="ease-out duration-200"
                        leave-from-class="opacity-100"
                        leave-to-class="opacity-0">
                    <div class="fixed inset-0 transition-opacity" v-show="active.visible" @click="cancel(modal)">
                        <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
                    </div>
                </transition>
                <span class="hidden sm:inline-block sm:align-middle sm:h-screen"></span>&#8203;
                <transition
                        enter-active-class="ease-out duration-300"
                        enter-from-class="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enter-to-class="opacity-100 translate-y-0 sm:scale-100"
                        leave-active-class="ease-out duration-200"
                        leave-from-class="opacity-100 translate-y-0 sm:scale-100"
                        leave-to-class="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                    <div v-show="active.visible"
                         class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
                         role="dialog" aria-modal="true" aria-labelledby="modal-headline">
                        <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                            <div class="sm:flex sm:items-start">
                                <slot>
                                    <div class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                                        <svg class="h-6 w-6 text-red-600" xmlns="http://www.w3.org/2000/svg" fill="none"
                                             viewBox="0 0 24 24" stroke="currentColor">
                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                                  d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"/>
                                        </svg>
                                    </div>
                                    <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                                        <h3 class="text-lg leading-6 font-medium text-gray-900" id="modal-headline">
                                            Empty modal
                                        </h3>
                                        <div class="mt-2">
                                            <p class="text-sm leading-5 text-gray-500">
                                                Please add modal body between &lt;Modal&gt; and &lt;/Modal&gt;
                                            </p>
                                        </div>
                                    </div>
                                </slot>
                            </div>
                        </div>
                        <slot name="footer" v-if="!hideFooter">
                            <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                                <span class="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto">
                                    <button type="button" @click="confirm(modal)"
                                            class="inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-custom-600 hover:bg-custom-500 focus:outline-none focus:border-custom-700 focus:shadow-outline-custom active:bg-custom-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-custom-500">
                                        Oke
                                    </button>
                                </span>
                                <span class="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto">
                                    <button type="button" @click="cancel(modal)"
                                            class="inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-custom-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-custom-500">
                                        Cancel
                                    </button>
                                </span>
                            </div>
                        </slot>
                    </div>
                </transition>
            </div>
        </div>
    </teleport>
</template>

<script lang="ts">
    import {defineComponent, reactive, watch, PropType, computed, compile} from 'vue';
    import {IPromise, usePromise} from './usePromise';
    import useUid from '@/components/useUid';
    import {StackItemType, stackStore} from "@/store/stack";
    import {confirm, cancel, cancelActive} from "@/components/useModal";

    export default defineComponent({
        name: "Modal",
        props: {
            modal: {
                type: Object as PropType<IPromise>,
            },
            hideFooter: {
                type: Boolean,
                default: false,
            }
        },
        mounted() {
            window.addEventListener('keyup', this.closeActiveModal)
        },
        unmounted() {
            window.removeEventListener('keyup', this.closeActiveModal)
        },
        setup(props, {slots}) {

            //  Get unique id
            const {uid} = useUid();

            //  Active state of modal
            let active = reactive({shown: false, visible: false});

            //  Watch for change of visible property
            watch(() => props.modal, (modal) => {
                const visible = Object.assign(usePromise, modal).visible;

                //  Register component in stack if becomes visible
                if (visible) {
                    stackStore.register(uid, StackItemType.Modal);
                    active.shown = true;
                } else {
                    setTimeout(() => active.shown = false, 500);
                }
                setTimeout(() => {
                    active.visible = visible;
                }, visible ? 25 : 0);
            }, {immediate: true, deep: true});

            //  Close this modal only if this is active, prevents closing entire stack with keypress esc
            const closeActiveModal = (event: any) => {
                if (event.key == 'Escape') {
                    setTimeout(() => cancelActive(props.modal as usePromise, uid), 25);
                }
            }

            //  Get stack position
            const position = computed(() => stackStore.position(uid));

            return {
                active,
                position,
                cancel,
                confirm,
                closeActiveModal,
            }

        }
    })
</script>