import {FormValidation} from "@/types/validation";
import {BaseField, Field} from "@/types/field";

export enum FormGrid {
    None,
    Columns12
}

export interface Form {
    grid: FormGrid,
    error: string,
    fields: Field[],
    validate: FormValidation,
    validated: boolean,
    submitted: boolean,
    onSubmitted: Function,
}

export class BaseForm implements Form {
    grid: FormGrid = FormGrid.Columns12;
    error: string = '';
    fields: Field[] = [];
    submitted: boolean = false;
    validate: FormValidation = FormValidation.AfterSubmit;
    validated: boolean = false;
    onSubmitted: Function = () => Promise.resolve();

    constructor(validate: FormValidation = FormValidation.AfterSubmit, grid: FormGrid = FormGrid.Columns12) {
        this.grid = grid;
        this.submitted = false;
        this.validate = validate;
        this.validated = false;
    }

    public setError(message: string): this {
        this.error = message;
        return this;
    }

    public submit(submit: boolean = true): Promise<any> {
        this.submitted = submit;
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                this.onSubmitted().then(() => resolve()).catch((reason: any) => reject(reason));
            }, 250);
        });
    }

    public removeFields(): BaseForm {
        this.fields = [];
        return this;
    }

    public addField(field: Field): BaseForm {
        this.fields.push(field);
        return this;
    }
}