<template>
    <div :class="{'px-4 py-5 sm:p-6':true,'sm:pb-4':!description&&!small,'sm:pt-4':!small,'sm:pt-2':small,'sm:pb-2':small&&!description}">
        <div class="flex">
            <div class="flex-grow">
                <h3 :class="{'font-medium text-gray-900':true,'text-lg leading-6':!small,'text-base leading-6':small}">{{ name }}</h3>
            </div>
            <slot name="actions"/>
        </div>
        <div class="mt-2 text-sm leading-5 text-gray-500" v-if="description" v-html="description">
        </div>
        <slot/>
    </div>
</template>

<script lang="ts">
    import {defineComponent} from 'vue';

    export default defineComponent({
        name: "CardHeader",
        props: {
            name: {
                type: String,
                default: '',
            },
            small: {
                type: Boolean,
                default: false,
            },
            description: {
                type: String,
                default: '',
            },
        },
        setup() {
        }
    })
</script>