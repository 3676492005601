import {inject} from "vue";

export class Clipboard {
    copy(value: string) {
        const dummy = document.createElement("textarea");
        document.body.appendChild(dummy);
        dummy.value = value;
        dummy.select();
        document.execCommand("copy");
        document.body.removeChild(dummy);
    }
}

export const ClipboardSymbol = Symbol();

export function useClipboard() {
    const notification = inject(ClipboardSymbol);
    if (!notification) throw new Error('Plugin not injected');
    return new Clipboard();
}

export default {
    install: (app: any, options: any) => {
        if (!options) options = {};
        const Plugin = new Clipboard();
        app.provide(ClipboardSymbol, Plugin)
    }
}