
    import {defineComponent, reactive, watch, PropType, computed, compile} from 'vue';
    import {IPromise, usePromise} from './usePromise';
    import useUid from '@/components/useUid';
    import {StackItemType, stackStore} from "@/store/stack";
    import {confirm, cancel, cancelActive} from "@/components/useModal";

    export default defineComponent({
        name: "Modal",
        props: {
            modal: {
                type: Object as PropType<IPromise>,
            },
            hideFooter: {
                type: Boolean,
                default: false,
            }
        },
        mounted() {
            window.addEventListener('keyup', this.closeActiveModal)
        },
        unmounted() {
            window.removeEventListener('keyup', this.closeActiveModal)
        },
        setup(props, {slots}) {

            //  Get unique id
            const {uid} = useUid();

            //  Active state of modal
            let active = reactive({shown: false, visible: false});

            //  Watch for change of visible property
            watch(() => props.modal, (modal) => {
                const visible = Object.assign(usePromise, modal).visible;

                //  Register component in stack if becomes visible
                if (visible) {
                    stackStore.register(uid, StackItemType.Modal);
                    active.shown = true;
                } else {
                    setTimeout(() => active.shown = false, 500);
                }
                setTimeout(() => {
                    active.visible = visible;
                }, visible ? 25 : 0);
            }, {immediate: true, deep: true});

            //  Close this modal only if this is active, prevents closing entire stack with keypress esc
            const closeActiveModal = (event: any) => {
                if (event.key == 'Escape') {
                    setTimeout(() => cancelActive(props.modal as usePromise, uid), 25);
                }
            }

            //  Get stack position
            const position = computed(() => stackStore.position(uid));

            return {
                active,
                position,
                cancel,
                confirm,
                closeActiveModal,
            }

        }
    })
