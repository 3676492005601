import {inject} from "vue";
import {loadStripe} from '@stripe/stripe-js/pure';
import {appStore} from "@/store/app";

export class Stripe {

    public async payment(sessionId: string) {
        loadStripe(appStore.getState().stripe.toString()).then((stripe: any) => {
            return stripe.redirectToCheckout({sessionId});
        });
    }

}

export const StripeSymbol = Symbol();

export function useStripe() {
    const stripe = inject(StripeSymbol);
    if (!stripe) throw new Error('Plugin not injected');
    return new Stripe();
}

export default {
    install: (app: any, options: any) => {
        app.provide(StripeSymbol, new Stripe())
    }
}