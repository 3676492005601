import {userStore} from "@/store/user";
import moment from "moment";

class dateHelper {
    public formatDate(date: Date, format: string | null = null) {
        return moment(date).locale(userStore.getState().locale).format(format ?? userStore.getState().date)
    }

    public formatDateTime(date: Date) {
        return moment(date).locale(userStore.getState().locale).format(userStore.getState().datetime)
    }
}

export function useDate() {
    return new dateHelper();
}