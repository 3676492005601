export interface IPagingLink {
    active: boolean,
    label: string,
    url: string | null,
}

export interface IPaging {
    current_page: number,
    from: number,
    last_page: number,
    per_page: number,
    to: number,
    total: number,
    path: string,
    links: IPagingLink[]
}

export class Paging implements IPaging {
    current_page: number = 0;
    from: number = 0;
    last_page: number = 0;
    links: IPagingLink[] = [];
    path: string = '';
    per_page: number = 0;
    to: number = 0;
    total: number = 0;
}