import {
    BaseValidationRule,
    MaxDateValidationRule,
    MinDateValidationRule,
    MinLengthValidationRule
} from "@/types/validation";
import {FieldOption, Option} from "@/types/option";
import {Logic} from "@/types/logic";
import {BaseForm} from "@/types/form";
import {useDate} from '@/plugins/date';
import moment from "moment";
import {ITooltip, Tooltip} from "@/types/tooltip";

export enum FieldType {
    Text,
    Textarea,
    Number,
    Select,
    SelectButton,
    Radio,
    Checkbox,
    Password,
    Date,
    Avatar,
    Stars,
    Tag,
    List
}

export interface Field {
    caption: string,
    model: string,
    type: FieldType,
    width: Number,
    autocomplete?: string,
    placeholder?: string,
    options: FieldOption[],
    rules: BaseValidationRule[],
    logic?: Logic,
    createButton?: string | null,
    createMethod: Function,
    translate: boolean,
    visible: boolean,
    maxItems: number;
    form: BaseForm;
    defaultValue: any;
    defaultFormValues: object;
    reorder: boolean;
    label: boolean,
    disabled: boolean,
    tooltip: Tooltip | null,
}

export class BaseField implements Field {
    model: string = '';
    caption: string = '';
    placeholder?: string = '';
    type: FieldType = FieldType.Text | FieldType.Password;
    width: number = 12;
    autocomplete?: string = '';
    options: FieldOption[] = [];
    rules: BaseValidationRule[] = [];
    logic?: Logic = undefined;
    createButton?: string | null = null;
    createMethod: Function = () => {
    };
    translate: boolean = true;
    visible: boolean = true;
    maxItems: number = -1;
    form: BaseForm = new BaseForm()
    defaultValue: any = null;
    defaultFormValues: object = {};
    reorder: boolean = false;
    label: boolean = true;
    disabled: boolean = false;
    tooltip: Tooltip | null = null;

    constructor(model: string, caption: string, placeholder?: string) {
        this.model = model;
        this.caption = caption;
        this.placeholder = placeholder;
        return this;
    }

    public setDefault(value: any): this {
        this.defaultValue = value;
        return this;
    }

    public preventTranslate(): this {
        this.translate = false;
        return this;
    }

    public setAutocomplete(autocomplete: string): this {
        this.autocomplete = autocomplete;
        return this;
    }

    public setWidth(width: number): this {
        this.width = width;
        return this;
    }

    public setLogic(logic: Logic): this {
        this.logic = logic;
        return this;
    }

    public removeRules(): this {
        this.rules = [];
        return this;
    }

    public addRule(rule: BaseValidationRule): this {
        this.rules.push(rule);
        return this;
    }

    public hideLabel(): this {
        this.label = false;
        return this;
    }

    public setDisabled(): this {
        this.disabled = true;
        return this;
    }

    public setEnabled(): this {
        this.disabled = false;
        return this;
    }

    public setTooltip(tooltip: Tooltip): this {
        this.tooltip = tooltip;
        return this;
    }
}

export class TextField extends BaseField {
    constructor(model: string, caption: string, placeholder?: string) {
        super(model, caption, placeholder);
        this.type = FieldType.Text;
    }
}

export class TagField extends BaseField {
    createPlaceholder: string = '';
    onChangedCallback: Function = () => {
    };

    constructor(model: string, caption: string, placeholder?: string) {
        super(model, caption, placeholder);
        this.type = FieldType.Tag;
    }

    public setCreatePlaceholder(placeholder: string): this {
        this.createPlaceholder = placeholder;
        return this;
    }

    public onChangedTags(callback: Function): this {
        this.onChangedCallback = callback;
        return this;
    }
}

export class TextareaField extends BaseField {
    rows: number = 3;

    constructor(model: string, caption: string, placeholder?: string) {
        super(model, caption, placeholder);
        this.type = FieldType.Textarea;
    }

    public setRows(rows: number): this {
        this.rows = rows;
        return this;
    }
}

export class NumberField extends BaseField {
    constructor(model: string, caption: string, placeholder?: string) {
        super(model, caption, placeholder);
        this.type = FieldType.Number;
    }
}

export class PasswordField extends BaseField {
    constructor(model: string, caption: string, placeholder?: string) {
        super(model, caption, placeholder);
        this.type = FieldType.Password;
    }
}

export class DateField extends BaseField {
    min: string | null = null;
    max: string | null = null;

    constructor(model: string, caption: string, placeholder?: string) {
        super(model, caption, placeholder);
        this.type = FieldType.Date;
    }

    addRule(rule: BaseValidationRule): this {
        const date = useDate();
        if (rule instanceof MinDateValidationRule) {
            this.min = date.formatDate(moment(rule.min).toDate(), 'YYYY-MM-DD');
        }
        if (rule instanceof MaxDateValidationRule) {
            this.max = date.formatDate(moment(rule.max).toDate(), 'YYYY-MM-DD');
        }
        return super.addRule(rule);
    }
}

class OptionBasedField extends BaseField {
    constructor(model: string, caption: string, placeholder?: string) {
        super(model, caption, placeholder);
    }

    public removeOptions(): this {
        this.options = [];
        return this;
    }

    public addOption(option: FieldOption): this {
        this.options.push(option);
        return this;
    }

    public setCreate(name: string, method: Function): this {
        this.createButton = name;
        this.createMethod = method;
        return this;
    }
}

export class MultipleOptionField extends OptionBasedField {

    multiple: boolean = false;

    constructor(model: string, caption: string, placeholder?: string) {
        super(model, caption, placeholder);
    }

    public setMultiple(multiple: boolean): this {
        this.multiple = multiple;
        return this;
    }
}

export class SelectField extends OptionBasedField {
    constructor(model: string, caption: string, placeholder?: string) {
        super(model, caption, placeholder);
        this.type = FieldType.Select;
    }
}

export class RadioField extends OptionBasedField {
    columns: number = 1;

    constructor(model: string, caption: string, placeholder?: string) {
        super(model, caption, placeholder);
        this.type = FieldType.Radio;
    }

    public setColumns(columns: number): this {
        this.columns = columns;
        return this;
    }
}

export class CheckboxField extends MultipleOptionField {
    columns: number = 1;

    constructor(model: string, caption: string, placeholder?: string) {
        super(model, caption, placeholder);
        this.type = FieldType.Checkbox;
    }

    public setColumns(columns: number): this {
        this.columns = columns;
        return this;
    }
}

export class SelectButtonField extends MultipleOptionField {
    constructor(model: string, caption: string, placeholder?: string) {
        super(model, caption, placeholder);
        this.type = FieldType.SelectButton;
    }
}

export class AvatarField extends BaseField {
    uploading: boolean = false;
    callback: Function = (file: File | null) => {
    };

    constructor(model: string, caption: string, placeholder?: string) {
        super(model, caption, placeholder);
        this.type = FieldType.Avatar;
    }

    public onChange(callback: Function): this {
        this.callback = callback;
        return this;
    }
}

export class StarsField extends BaseField {
    hover: number | null = null;
    stars: number = 5;
    half: boolean = false;

    constructor(model: string, caption: string, placeholder?: string) {
        super(model, caption, placeholder);
        this.type = FieldType.Stars;
    }

    public setStars(stars: number): this {
        this.stars = stars;
        return this;
    }

    public setHalf(half: boolean): this {
        this.half = half;
        return this;
    }
}

export class ListField extends BaseField {
    emptyItem: string = '';
    show: boolean = false;

    constructor(model: string, caption: string, placeholder?: string) {
        super(model, caption);
        this.emptyItem = '';
        this.type = FieldType.List;
    }

    public addToList(field: ListField, items: { [key: string]: any }) {
        if(field.emptyItem.toString().trim() !== '') {
            items.push(field.emptyItem);
            field.emptyItem = '';
        }
    }

    public showNumbers(showNumbers: boolean): this {
        this.show = showNumbers;
        return this;
    }
}