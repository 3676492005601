
    import {defineComponent, PropType, reactive, ref, watch} from 'vue';
    import {ActionType, BaseAction, BaseActionGroup, Pagination, Table} from "@/types/table";

    export default defineComponent({
        name: "DataTable",
        props: {
            table: {
                type: Object as PropType<Table>,
                required: true,
            },
            dataset: {
                type: Array,
                default: () => []
            },
            empty: {
                type: String,
                default: 'table.empty'
            },
            borderTop: {
                type: Boolean,
                default: true,
            }
        },
        setup: function (props) {

            //  Table object
            const table = ref<Table>(props.table).value;

            //  Dataset
            const dataset = ref(props.dataset).value;

            //  Pagination previous
            const previous = () => {
                if (table.page.current > 1) {
                    table.gotoPage(table.page.current--);
                }
            }

            //  Pagination next
            const next = () => {
                if (table.page.current * table.page.perPage < table.page.results) {
                    table.gotoPage(table.page.current++);
                }
            }

            //  Pagination goto page
            const goto = (page: number) => {
                table.page.current = page;
                table.gotoPage(table.page.current);
            }

            //  Check if page is empty and not is first
            watch(() => dataset, (dataset) => {
                if (dataset.length === 0 && table.page.current > 1) {
                    goto(1);
                }
            }, {immediate: true, deep: true});

            const details = reactive({
                active: -1
            });

            let hideTimeout: any = null;
            const showActionMenu = (index: number) => {
                clearTimeout(hideTimeout);
                details.active = index;
            }
            const hideActionMenu = () => {
                hideTimeout = setTimeout(() => {
                    details.active = -1;
                }, 500);
            }


            //  Calculate items on current page
            const paginationNumber: number[] = [];
            let pages: number = 0;
            let start: number = 0;
            let end: number = 0;
            let paging = reactive({
                pagination: paginationNumber,
                pages,
                start,
                end
            });
            const updatePagination = () => {
                paging.pages = Math.ceil(table.page.results / table.page.perPage);
                paging.start = ((table.page.current - 1) * table.page.perPage) + 1;
                paging.end = table.page.current * table.page.perPage < table.page.results ? table.page.current * table.page.perPage : table.page.results;
                paging.pagination = []
                if (paging.pages <= 7) {
                    for (let i = 1; i <= paging.pages; i++) {
                        paging.pagination.push(i);
                    }
                } else {
                    if (table.page.current < 4) {
                        for (let i = 1; i <= 4; i++) {
                            paging.pagination.push(i);
                        }
                        paging.pagination.push(-1);
                        paging.pagination.push(paging.pages);
                    } else if (table.page.current > paging.pages - 3) {
                        paging.pagination.push(1);
                        paging.pagination.push(-1);
                        for (let i = paging.pages - 3; i <= paging.pages; i++) {
                            paging.pagination.push(i);
                        }
                    } else {
                        paging.pagination.push(1);
                        paging.pagination.push(-1);
                        for (let i = table.page.current - 1; i <= table.page.current + 1; i++) {
                            paging.pagination.push(i);
                        }
                        paging.pagination.push(-1);
                        paging.pagination.push(paging.pages);
                    }
                }
            }

            const isInvisible = (action: BaseAction, row: object): boolean => {
                return action.isInvisible(row);
            }

            const isDisabled = (action: BaseAction, row: object): boolean => {
                return action.isDisabled(row);
            }

            const click = (action: BaseAction, row: object, index: number, event: any): void => {
                event.target.blur();
                if (!isInvisible(action, row) || !isDisabled(action, row)) {
                    action.click(row, index);
                }
            }

            const availableActions = (row: object): Array<any> => {
                const actionGroups: BaseActionGroup[] = [];
                let lastGroupIndex: number | null = null;
                table.actions.forEach((actionGroup, groupIndex) => {
                    actionGroup.actions.forEach(action => {
                        if (!isInvisible((action as BaseAction), row)) {
                            if (lastGroupIndex != groupIndex) {
                                actionGroups.push(new BaseActionGroup());
                                lastGroupIndex = groupIndex;
                            }
                            (actionGroups[actionGroups.length - 1] as BaseActionGroup).addAction(action as BaseAction);
                        }
                    })

                })
                return actionGroups;
            };

            watch(() => table, (table) => {
                updatePagination();
            }, {immediate: true, deep: true});

            return {
                Pagination,
                ActionType,
                pages,
                start,
                end,
                goto,
                previous,
                next,
                details,
                paging,
                showActionMenu,
                hideActionMenu,
                isDisabled,
                isInvisible,
                click,
                availableActions,
            }
        }
    })
