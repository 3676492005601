import {inject, reactive} from 'vue';
import {NotificationModal, notificationStore, NotificationType} from "@/store/notification";

export class Notification {

    info(title: string, message?: string, duration?: number) {
        this.show(NotificationType.Info, title, message, duration);
    }

    message(title: string, message?: string, duration?: number) {
        this.show(NotificationType.Message, title, message, duration);
    }

    error(title: string, message?: string, duration?: number) {
        this.show(NotificationType.Error, title, message, duration);
    }

    success(title: string, message?: string, duration?: number) {
        this.show(NotificationType.Success, title, message, duration);
    }

    show(type: NotificationType, title: string, message?: string, duration?: number) {
        const notification = reactive(new NotificationModal());
        notification.type = type;
        notification.title = title;
        notification.duration = duration || 4000;
        notification.message = message;
        notification.show = () => {
            notification.visible = true;
        }
        notification.hide = () => {
            notification.visible = false;
            setTimeout(() => notificationStore.delete(notification), 500);
        }
        notificationStore.show(notification);
    }

}

export const NotificationSymbol = Symbol();

export function useNotification() {
    const notification = inject(NotificationSymbol);
    if (!notification) throw new Error('Plugin not injected');
    return new Notification();
}

export default {
    install: (app: any, options: any) => {
        if (!options) options = {};
        const Plugin = new Notification();
        app.config.globalProperties.$notification = Plugin;
        app.provide(NotificationSymbol, Plugin)
    }
}