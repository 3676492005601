<template>
    <button type="button" :class="{
        'ml-3 inline-flex items-center px-4 py-2 border text-sm leading-5 font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-custom-500':true,
        'border-transparent text-white bg-custom-600 hover:bg-custom-500 focus:outline-none focus:border-custom-700 focus:shadow-outline-custom active:bg-custom-700':primary,
        'order-gray-300 text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-custom-300 focus:shadow-outline-custom active:text-gray-800 active:bg-gray-50':!primary,
        }">
        {{ text }}
    </button>
</template>

<script lang="ts">
    import {defineComponent} from 'vue';

    export default defineComponent({
        name: "CardFooterButton",
        props: {
            text: {
                type: String,
                default: '',
            },
            primary: {
                type: Boolean,
                default: true
            }
        },
        setup() {
        }
    })
</script>