import {reactive} from 'vue';
import ls from 'local-storage';
import {Store} from './store';
import {$axios} from "@/plugins/axios";

export type Country = {
    code: string,
    name: string
}

export class CountryModel implements Country {
    code: string = '';
    name: string = '';
}

export type Brand = {
    id: string,
    name: string,
}

export class BrandModel implements Brand {
    id: string = '';
    name: string = '';
}

export type App = {
    countries: Country[],
    brands: Brand[],
    campaigns: String[],
    stripe: String,
}

export class AppModel implements App {
    countries: Country[] = [];
    brands: Brand[] = [];
    campaigns: String[] = [];
    stripe: String = '';
}

class AppStore extends Store <App> {
    protected data(): App {
        return {
            countries: [],
            campaigns: [],
            brands: [],
            stripe: '',
        };
    }

    constructor() {
        super();
        //  Load user state from local storage
        this.state = reactive(Object.assign(new AppModel, ls('app')));
    }

    fetchConfig(): Promise<any> {
        return new Promise((resolve, reject) => {
            $axios.get(`app/config`)
                .then(response => response.data)
                .then(response => {
                    this.updateKeyValue('stripe', response.stripe_public_key);
                    this.updateKeyValue('campaigns', response.campaign_types);
                    resolve();
                })
                .catch(error => reject(error))
        });
    }

    fetchBrands(): Promise<any> {
        return new Promise((resolve, reject) => {
            $axios.get(`brand-names`)
                .then(response => response.data)
                .then(response => {
                    const brands: Brand[] = [];
                    response['brand-names'].forEach((brand: Brand) => {
                        brands.push(brand);
                    });
                    this.updateKeyValue('brands', brands);
                    resolve();
                })
                .catch(error => reject(error));
        });
    }

    fetchCountries(): Promise<any> {
        return new Promise((resolve, reject) => {
            $axios.get(`app/countries`)
                .then(response => response.data)
                .then(response => {
                    const countries: Country[] = [];
                    Object.keys(response.countries).forEach((countryKey: string) => {
                        let country = new CountryModel();
                        country.code = response.countries[countryKey].key;
                        country.name = response.countries[countryKey].name;
                        countries.push(country);
                    });
                    this.updateKeyValue('countries', countries);
                    resolve();
                })
                .catch(error => reject(error));
        })
    }

    updateKeyValue(key: string, value: any) {
        const user: { [key: string]: any } = Object.assign(new AppModel(), ls('app'));
        user[key] = value;
        this.state[key] = value;
        ls('app', this.state);
    }

}

export const appStore: AppStore = new AppStore();