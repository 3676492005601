
import {computed, defineComponent, inject, watchEffect} from 'vue';
import useUid from "@/components/useUid";
import {BaseStep} from "@/types/steps";
import {BaseTab, BaseTabs} from "@/types/tabs";

export default defineComponent({
  name: "Tab",
  props: {
    name: {
      type: String,
      default: '',
    },
    badge: {
      type: Number,
      default: 0,
    },
    icon: {
      type: String,
      default: '',
    }
  },
  mounted() {
    this.registerTab(useUid().uid.toString());
  },
  unmounted() {
    this.removeStep();
  },
  setup(props) {

    const tabs = inject('tabs');

    let tabUid: string | null = null;

    const registerTab = (uid: string): void => {
      //  Check if step already exists
      // @ts-ignore
      const existing = tabs.tabs.filter(tab => tab.name === props.name && !tab.visible);

      if (existing.length === 1) {
        // @ts-ignore
        tabUid = existing[0].uid
        existing[0].visible = true;
      } else {
        tabUid = uid;
        const tab: BaseTab = new BaseTab(uid, props.name, props.icon);
        if (props.badge > 0) {
          tab.setBadge(props.badge);
        }
        // @ts-ignore
        tabs.tabs.push(tab);
      }
    }

    watchEffect(() => {
      const badge = props.badge;
      // @ts-ignore
      const stepIndex = tabs.tabs.map(tab => tab.uid).indexOf(tabUid);
      // @ts-ignore
      if(badge && tabs.tabs[stepIndex]) {
        // @ts-ignore
        (tabs.tabs[stepIndex] as BaseTab).setBadge(props.badge);
      }
    })

    const removeStep = (): void => {
      // @ts-ignore
      const stepIndex = tabs.tabs.map(tab => tab.uid).indexOf(tabUid);

      // @ts-ignore
      tabs.tabs[stepIndex].visible = false;

      // @ts-ignore
      // steps.steps.splice(stepIndex, 1);
    }

    // @ts-ignore
    const index = computed(() => tabs.tabs.map(tab => tab.uid).indexOf(tabUid))

    return {
      tabs,
      index,
      registerTab,
      removeStep
    }
  }
})
