
    import {defineComponent, inject, computed} from 'vue';
    import useUid from '@/components/useUid';
    import {BaseStep} from '@/types/steps';

    export default defineComponent({
        name: "Step",
        props: {
            name: {
                type: String,
                default: '',
            },
            description: {
                type: String,
                default: '',
            },
            icon: {
                type: String,
                default: '',
            },
            loading: {
                type: Boolean,
                default: false,
            },
            previous: {
                type: String,
                default: 'steps.previous'
            },
            next: {
                type: String,
                default: 'steps.next'
            },
            valid: {
                type: Function,
                default: () => Promise.resolve()
            },
            load: {
                type: Function,
                default: () => {}
            },
            saving: {
                type: String,
                default: 'loading.loading',
            }

        },
        mounted() {
            this.registerStep(useUid().uid.toString());
        },
        unmounted() {
            this.removeStep();
        },
        setup(props) {

            const steps = inject('steps');

            let stepUid: string | null = null;

            const registerStep = (uid: string): void => {
                //  Check if step already exists
                // @ts-ignore
                const existing = steps.steps.filter(step => step.name === props.name && !step.visible);

                if(existing.length === 1) {
                    // @ts-ignore
                    stepUid = existing[0].uid
                    existing[0].visible = true;
                }
                else {
                    stepUid = uid;
                    // @ts-ignore
                    steps.steps.push(new BaseStep(uid, props.name, props.description, props.icon, props.previous, props.next, props.valid, props.saving, props.load));
                    // @ts-ignore
                    if(steps.steps.length === 1) {
                        props.load();
                    }
                }
            }

            const removeStep = () : void => {
                // @ts-ignore
                const stepIndex = steps.steps.map(step => step.uid).indexOf(stepUid);

                // @ts-ignore
                steps.steps[stepIndex].visible = false;

                // @ts-ignore
                // steps.steps.splice(stepIndex, 1);
            }

            // @ts-ignore
            const index = computed(() => steps.steps.filter(step => step.visible).map(step => step.uid).indexOf(stepUid))

            return {
                steps,
                index,
                registerStep,
                removeStep,
            }

        }
    })
