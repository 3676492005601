<template>
    <Drawer :drawer="ticketDrawer"
            :title="$translate(`support.raise-a-ticket`)"
            :description="$translate(`support.support`)" width="max-w-3xl">
        <div class="px-4 py-5 sm:p-6">
            <DataForm v-model:form="form" v-model="values"/>
        </div>
        <template #footer>
            <div class="flex flex-shrink-0 px-4 border-t border-gray-200 py-5 sm:px-6">
                <div class="flex-grow flex items-center">
                    <transition
                            enter-active-class="ease-out duration-300"
                            enter-from-class="opacity-0"
                            enter-to-class="opacity-100"
                            leave-active-class="ease-out duration-200"
                            leave-from-class="opacity-100"
                            leave-to-class="opacity-0">
                        <div class="text-gray-500" v-show="ticketDrawer.saving">
                            <i class="fal fa-spinner-third fa-spin p-2"></i>
                            <span class="text-sm leading-5 text-center pl-2">{{ $translate('form.saving') }}</span>
                        </div>
                    </transition>
                </div>
                <div class="flex flex-row-reverse">
                    <div class="space-x-3 flex justify-end">
                        <span class="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto">
                            <button type="button"
                                    @click="ticketDrawer.response(false)"
                                    class="inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-custom-300 focus:shadow-outline-custom focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-custom-500 sm:text-sm sm:leading-5">
                                {{ $translate('form.cancel') }}
                            </button>
                        </span>
                        <span class="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto">
                            <button type="button"
                                    @click="ticketDrawer.response(true)"
                                    :class="`inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-custom-600 hover:bg-custom-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-custom-500`">
                                {{ $translate(`support.ticket.submit`) }}
                            </button>
                        </span>
                    </div>
                </div>
            </div>
        </template>
    </Drawer>
</template>

<script lang="ts">
    import {defineComponent, reactive, watch} from 'vue';
    import {usePromise} from "@/components/usePromise";
    import {BaseForm} from "@/types/form";
    import {TextareaField, TextField} from "@/types/field";
    import {MinLengthValidationRule, RequiredValidationRule} from "@/types/validation";
    import {getErrorMessage, useAxios} from "@/plugins/axios";
    import {useNotification} from "@/plugins/notification";
    import {useTranslation} from "@/plugins/i18n";

    export default defineComponent({
        name: "CreateTicket",
        props: {
            visible: {
                type: Boolean,
                default: false
            }
        },
        emits: ['update:visible', 'created'],
        setup(props, {emit}) {

            const axios = useAxios();

            const notification = useNotification();

            const i18n = useTranslation();

            const ticketDrawer = reactive(new usePromise());
            const show = async () => {
                const confirmed = await ticketDrawer.show();
                if (confirmed) {
                    form.setError('').submit().then(() => {
                        ticketDrawer.saving = true;
                        axios.post(`support`, values).then(() => {
                            notification.success(
                                i18n.translate('support.ticket.created.title'),
                                i18n.translate('support.ticket.created.description')
                            )
                            ticketDrawer.saving = false;
                            emit('created');
                            ticketDrawer.hide();
                        }).catch(error => {
                            ticketDrawer.saving = false;
                            form.setError(getErrorMessage(error.response.data));
                            show();
                        })
                    }).catch(error => {
                        ticketDrawer.saving = false;
                        form.setError(error);
                        show();
                    });
                } else {
                    emit('update:visible', false);
                }
            }

            watch(() => props.visible, (visible) => {
                if (visible) {
                    values.subject = '';
                    values.message = '';
                    show();
                }
            }, {immediate: true})

            const form = reactive(new BaseForm());
            form.addField(new TextField('subject', 'support.ticket.subject')
                .addRule(new RequiredValidationRule()));
            form.addField(new TextareaField('message', 'support.ticket.question')
                .setRows(5)
                .addRule(new RequiredValidationRule())
                .addRule(new MinLengthValidationRule(20)));

            const values = reactive({
                subject: '',
                message: '',
            });

            return {
                ticketDrawer,
                form,
                values,
            }

        }
    })
</script>