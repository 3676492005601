import {Store} from "@/store/store";

export enum ConfirmType {
    Info,
    Warning,
    Error
}

export type Confirm = {
    visible: boolean,
    type: ConfirmType,
    title: string,
    message: string,
    cancel: string,
    submit: string,
    confirmed(): void,
    rejected(): void,
}

export class ConfirmModal implements Confirm {
    visible: boolean = false;
    type: ConfirmType = ConfirmType.Info;
    title: string = '';
    message: string = '';
    cancel: string = '';
    submit: string = '';
    confirmed(): void {}
    rejected(): void {}
}

class ConfirmStore extends Store <Confirm> {

    protected data(): Confirm {
        return {
            visible: false,
            type: ConfirmType.Info,
            title: '',
            message: '',
            cancel: '',
            submit: '',
            confirmed() {},
            rejected() {},
        };
    }

    constructor() {
        super();
    }

    show(modal: ConfirmModal): void {
        if(!this.state.visible) {
            Object.assign(this.state, modal);
            this.state.visible = true;
        }
    }

    confirmed(): void {
        this.state.visible = false;
        this.state.confirmed();
    }

    rejected(): void {
        this.state.visible = false;
        this.state.rejected();
    }
}

export const confirmStore: ConfirmStore = new ConfirmStore();