export enum Pagination {
    None,
    Simple,
    Numbers
}

export enum ActionType {
    Default,
    Primary,
    Delete,
    Link,
}

export interface Column {
    caption: string
    model: string
    mobile: boolean
    sortable: boolean
    translate: boolean
}

export interface Page {
    current: number
    results: number
    perPage: number
}

export class LaravelPagination {
    current_page: number = 0;
}

export interface Action {
    caption: string
    icon: string
    translate: boolean
    type: ActionType
    click: Function
    clickCallback: Function
    disableCallback: Function
}

export interface ActionGroup {
    actions: Action[]
}

export interface Table {
    loading: boolean;
    pagination: Pagination;
    page: Page;
    columns: Column[];
    gotoPage: Function;
    onChanged: Function;
    actions: ActionGroup[];
}

export class BaseActionGroup implements ActionGroup {
    actions: Action[] = [];

    public addAction(action: BaseAction): this {
        this.actions.push(action);
        return this;
    }
}

export class BaseAction implements Action {
    caption: string = '';
    icon: string = '';
    translate: boolean = true;
    type: ActionType = ActionType.Default;
    disableCallback: Function = (): boolean => false;
    invisibleCallback: Function = (): boolean => false;
    click: Function = (record: object, index: number) => {
        this.clickCallback(record, index);
    };
    clickCallback: Function = (record: object) => {
    };

    constructor(caption: string, type: ActionType = ActionType.Default) {
        this.caption = caption;
        this.type = type;
        this.setIcon('far fa-edit');
    }

    public setIcon(icon: string): this {
        this.icon = icon;
        return this;
    }

    public onClick(callback: Function): this {
        this.clickCallback = callback;
        return this;
    }

    public preventTranslate(): this {
        this.translate = false;
        return this;
    }

    public isDisabled(row: object): boolean {
        return this.disableCallback(row);
    }

    public disable(callback: Function): this {
        this.disableCallback = callback;
        return this;
    }

    public isInvisible(row: object): boolean {
        return this.invisibleCallback(row);
    }

    public invisible(callback: Function): this {
        this.invisibleCallback = callback;
        return this;
    }
}

export class LinkAction extends BaseAction {
    constructor(caption: string) {
        super(caption, ActionType.Link);
        this.setIcon('far fa-link');
    }
}

export class DeleteAction extends BaseAction {
    constructor(caption: string) {
        super(caption, ActionType.Delete);
        this.setIcon('far fa-trash');
    }
}

export class PrimaryAction extends BaseAction {
    constructor(caption: string) {
        super(caption, ActionType.Primary);
    }
}

export class BasePage implements Page {
    current: number = 1;
    results: number = 0;
    perPage: number = 10;
}

export class BaseTable implements Table {
    loading: boolean = false;
    columns: Column[] = [];
    page: Page = new BasePage;
    pagination: Pagination = Pagination.Simple;
    actions: ActionGroup[] = [];
    gotoPage: Function = (page: number) => {
        this.loading = true;
        this.onChanged(this.page.current);
    };
    onChanged: Function = (page: number) => {
    };

    constructor(pagination: Pagination = Pagination.Simple) {
        this.columns = [];
        this.pagination = pagination;
    }

    public addColumn(column: BaseColumn): this {
        this.columns.push(column);
        return this;
    }

    public addActionGroup(group: ActionGroup): this {
        this.actions.push(group);
        return this;
    }

    public addAction(action: BaseAction): this {
        this.actions.push(new BaseActionGroup().addAction(action))
        return this;
    }

    public setResultsPerPage(perPage: number): this {
        this.page.perPage = perPage;
        return this;
    }

    public setResults(results: number): this {
        this.page.results = results;
        return this;
    }

    public onChangedPage(callback: Function): this {
        this.onChanged = callback;
        return this;
    }

    public finishLoading(): this {
        this.loading = false;
        return this;
    }
}

export class BaseColumn implements Column {
    caption: string = '';
    model: string = '';
    mobile: boolean = true;
    sortable: boolean = false;
    translate: boolean = true;

    public constructor(model: string, caption: string) {
        this.model = model;
        this.caption = caption;
    }

    public preventTranslate(): this {
        this.translate = false;
        return this;
    }

    public hideMobile(): this {
        this.mobile = false;
        return this;
    }

    public showMobile(): this {
        this.mobile = true;
        return this;
    }
}

export class TextColumn extends BaseColumn {

}