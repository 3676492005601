
    import {defineComponent} from 'vue';

    export default defineComponent({
        name: "CardFooterButton",
        props: {
            text: {
                type: String,
                default: '',
            },
            primary: {
                type: Boolean,
                default: true
            }
        },
        setup() {
        }
    })
