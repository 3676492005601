<template>
    <Modal :modal="modal">
        <div :class="`mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-${style.color}-100 sm:mx-0 sm:h-10 sm:w-10 text-${style.color}-600 text-base`"
             v-if="style.icon">
            <i :class="style.icon"/>
        </div>
        <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
            <h3 class="text-lg leading-6 font-medium text-gray-900" id="modal-headline">
                {{ confirm.title }}
            </h3>
            <div class="mt-2">
                <p class="text-sm leading-5 text-gray-500">
                    {{ confirm.message }}
                </p>
            </div>
        </div>
        <template #footer>
            <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                <span class="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto">
                    <button type="button"
                            @click="modal.response(true)"
                            :class="`inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-${style.color}-600 hover:bg-${style.color}-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-${style.color}-500`">
                        {{ $translate(confirm.submit) }}
                    </button>
                </span>
                <span class="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto">
                    <button type="button"
                            @click="modal.response(false)"
                            class="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-custom-500">
                        {{ $translate(confirm.cancel) }}
                    </button>
                </span>
            </div>
        </template>
    </Modal>
</template>

<script lang="ts">
    import {defineComponent, computed, reactive, watch} from 'vue';
    import {confirmStore} from "@/store/confirm";
    import {usePromise} from "@/components/usePromise";
    import {ConfirmType} from '@/store/confirm';

    export default defineComponent({
        name: "Confirm",
        setup() {

            //  Get confirm state from store
            const confirm = computed(() => confirmStore.getState());

            let style = reactive({
                icon: '',
                color: 'red'
            });

            const modal = reactive(new usePromise());
            const showConfirm = async () => {
                if (await modal.show()) {
                    confirmStore.confirmed();
                    setTimeout(() => {
                        modal.hide();
                    }, 50);
                } else {
                    confirmStore.rejected();
                }
            }

            //  Watch visibility change
            watch(() => confirm, (confirm) => {
                if (confirm.value.visible) {
                    showConfirm();
                }
                switch (confirm.value.type) {
                    case ConfirmType.Info:
                        style.icon = 'fal fa-info-circle';
                        style.color = 'custom';
                        break;
                    case ConfirmType.Warning:
                        style.icon = '';
                        style.color = 'orange';
                        break;
                    case ConfirmType.Error:
                        style.icon = 'fal fa-exclamation-triangle';
                        style.color = 'red';
                        break;

                }
            }, {
                immediate: true,
                deep: true
            })

            return {
                style,
                confirm,
                modal
            }

        }
    })
</script>