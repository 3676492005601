<template>
    <div class="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6">
        <div class="flex-1 flex justify-between sm:hidden">
            <a href="#"
               @click.prevent="prev"
               :class="{
                   'relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:text-gray-500': true,
                   'opacity-50': pagination.current_page === 1
               }">
                {{ previousLabel }}
            </a>
            <a href="#"
               @click.prevent="next"
               :class="{
                   'ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:text-gray-500':true,
                   'opacity-50': pagination.current_page === pagination.last_page
               }">
                {{ nextLabel }}
            </a>
        </div>
        <div class="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
            <div>
                <p class="text-sm text-gray-700" v-html="information"/>
            </div>
            <div>
                <nav class="relative z-0 inline-flex shadow-sm -space-x-px" aria-label="Pagination"
                     v-if="pagination.total > 1">
                    <a href="#"
                       @click.prevent="prev"
                       :class="{
                           'relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50':true,
                           'opacity-50': pagination.current_page === 1
                       }">
                        <span class="sr-only">Previous</span>
                        <!-- Heroicon name: solid/chevron-left -->
                        <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                             fill="currentColor" aria-hidden="true">
                            <path fill-rule="evenodd"
                                  d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                                  clip-rule="evenodd"/>
                        </svg>
                    </a>
                    <component
                            href="#"
                            :class="{
                                'relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50': page,
                                'relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700': !page
                            }"
                            v-for="(page, index) in pages" :key="`pagenumber_${index}`"
                            @click.prevent="goto(page)"
                            :is="page ? 'a' : 'span'">
                        {{ page ?? '...' }}
                    </component>
                    <a href="#"
                       @click.prevent="next"
                       :class="{
                           'relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50':true,
                           'opacity-50': pagination.current_page === pagination.last_page
                       }">
                        <span class="sr-only">Next</span>
                        <!-- Heroicon name: solid/chevron-right -->
                        <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                             fill="currentColor" aria-hidden="true">
                            <path fill-rule="evenodd"
                                  d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                                  clip-rule="evenodd"/>
                        </svg>
                    </a>
                </nav>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
    import {defineComponent, PropType, computed, watch, ref, Ref} from 'vue';
    import {Paging} from "@/interfaces/pagination";

    export default defineComponent({
        name: "Pagination",
        props: {
            pagination: {
                type: Object as PropType<Paging>,
                default: () => new Paging()
            },
            label: {
                type: String,
                default: 'Showing {from} to {to} of {total} results'
            },
            previousLabel: {
                type: String,
                default: 'Previous',
            },
            nextLabel: {
                type: String,
                default: 'Next',
            },
        },
        emits: ['on-page-changed'],
        setup(props, {emit}) {
            const prev = () => goto(props.pagination.current_page - 1)
            const next = () => goto(props.pagination.current_page + 1)
            const goto = (page: number) => {
                if (page > 0 && page <= props.pagination.last_page) {
                    emit('on-page-changed', page);
                }
            }
            const information = computed((): string => {
                return props.label
                    .replace(`{from}`, `<span class="font-medium">${props.pagination.from ?? ''}</span>`)
                    .replace(`{to}`, `<span class="font-medium">${props.pagination.to ?? ''}</span>`)
                    .replace(`{total}`, `<span class="font-medium">${props.pagination.total ?? ''}</span>`);
            });

            const pages: Ref<(number | null)[]> = ref([]);
            watch(() => props.pagination, (pagination) => {
                pages.value = [];


                if (props.pagination.last_page < 10) {
                    for (let i = 1; i <= props.pagination.last_page; i++) {
                        pages.value.push(i);
                    }
                } else {
                    if (props.pagination.current_page <= 3 || props.pagination.current_page > props.pagination.last_page - 3) {
                        for (let i = 1; i <= 4; i++) {
                            pages.value.push(i);
                        }
                        pages.value.push(null);
                        for (let i = props.pagination.last_page - 3; i <= props.pagination.last_page; i++) {
                            pages.value.push(i);
                        }
                    } else {
                        pages.value.push(1);
                        pages.value.push(null);
                        for (let i = props.pagination.current_page - 1; i <= props.pagination.current_page + 1; i++) {
                            pages.value.push(i);
                        }
                        pages.value.push(null);
                        pages.value.push(props.pagination.last_page);
                    }
                }
            }, {deep: true, immediate: true});

            return {
                prev,
                next,
                goto,
                pages,
                information,
            }
        }
    })
</script>