
    import {defineComponent} from 'vue';

    export default defineComponent({
        name: "CardHeader",
        props: {
            name: {
                type: String,
                default: '',
            },
            small: {
                type: Boolean,
                default: false,
            },
            description: {
                type: String,
                default: '',
            },
        },
        setup() {
        }
    })
