
    import {defineComponent, reactive, watch} from 'vue';
    import {usePromise} from "@/components/usePromise";
    import {useAxios} from "@/plugins/axios";
    import CreateTicket from "@/pages/support/CreateTicket.vue";
    import {faqStore} from "@/store/faq";

    export default defineComponent({
        name: "ViewFaqArticle",
        components: {
            CreateTicket,
        },
        props: {
            id: {
                type: [Number, String],
                default: '',
            },
            visible: {
                type: Boolean,
                default: false,
            }
        },
        emits: ['update:visible', 'all'],
        setup(props, {emit}) {

            const axios = useAxios();

            const faq = reactive({
                loading: false,
                exists: true,
                title: '',
                article: '',
                create: false,
            })

            const faqDrawer = reactive(new usePromise());
            const show = async () => {
                const confirmed = await faqDrawer.show();
                emit('update:visible', false);
            }

            const fetch = () => {
                faq.loading = true;
                axios.get(`faq/article/${props.id}`)
                    .then(response => response.data)
                    .then(response => {
                        faq.title = response.title;
                        faq.article = response.article;
                        faq.loading = false;
                        faq.exists = true;
                    }).catch(() => {
                    faq.loading = false;
                    faq.exists = false;
                });
            }

            watch(() => props.visible, (visible) => {
                if (visible) {
                    show();
                    fetch();
                }
            }, {immediate: true})

            const createTicket = () => {
                faq.create = true;
            }

            const ticketCreated = () => {
                faq.create = false;
                faqDrawer.hide();
            }

            const showFaq = () => {
                emit('all');
                faqDrawer.hide();
            }

            return {
                store: faqStore.getState(),
                faq,
                faqDrawer,
                createTicket,
                ticketCreated,
                showFaq
            }
        }
    })
