import {inject} from "vue";
import router from "@/router";
import {userStore} from "@/store/user";
import Axios from "axios";


export const $axios = Axios.create({
    baseURL: process.env.VUE_APP_BACKEND_API,
    headers: {
        'Content-Type': 'application/json',
    },
});

$axios.interceptors.request.use(config => {
    const token = userStore.getState().token;
    if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
})

$axios.interceptors.response.use(response => response, (error => {
    //  Check if user is not authenticated
    if (error.response.status === 401) {
        userStore.logout(true).then(() => {
            window.location.href = '/login';
        }).catch(() => {});
    }
    return Promise.reject(error);
}))

export const AxiosSymbol = Symbol();

export function getErrorMessage(errors: object): string {
    // @ts-ignore
    if (errors.errors && Object.keys(errors.errors).length > 0) {
        // @ts-ignore
        return errors.errors[Object.keys(errors.errors)[0]][0];
    }
    // @ts-ignore
    return errors.message || errors.error || '';
}

export function useAxios() {
    const notification = inject(AxiosSymbol);
    if (!notification) throw new Error('Plugin not injected');
    return $axios;
}

export default {
    install: (app: any, options: any) => {
        if (!options) options = {};
        app.config.globalProperties.$axios = $axios;
        app.provide(AxiosSymbol, $axios);
    }
}